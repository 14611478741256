import { FaAngleDown, FaAngleUp, FaTrashAlt, FaCheck, FaEyeSlash, FaInfoCircle } from "react-icons/fa";
import AnimateHeight, { Height } from "react-animate-height"
import { useEffect, useRef, useState } from "react";

export default function ArticleCarouselTile({ articleText, artikleUrl, artikleImageSrc, ...props }:
    { articleText: string, artikleUrl: string, artikleImageSrc: any }) {
    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", backgroundColor: "var(--background-2)", padding: "10px", borderRadius: "10px", margin: "20px", height: "250px", overflow: "hidden" }}>
            <div style={{ width: "60%", textAlign: "start", justifySelf: "flex-start", alignSelf: "center", display: "flex", flexDirection: "column", overflow: "hidden", position: "relative" }}>
                <div style={{ overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 10, WebkitBoxOrient: "vertical" }}>
                    {articleText}
                </div>
            </div>
            <div style={{ width: "30%", marginLeft: "10px" }}>
                <a href={artikleUrl} target="_blank" rel="noopener noreferrer" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img
                        src={artikleImageSrc}
                        alt="Article Image"
                        style={{ width: '100%', height: 'auto', marginTop: '20px' }} // Adjust size as needed
                    />
                </a>
            </div>
        </div>
    );
}