/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import LLMIcon from './Icons/LLMIcon'
import { useEffect } from 'react';
const LLMSelectSingleValueContainer = ({ cx, children, getStyles, innerRef, innerProps, ...props }) => {
    const { isDisabled, isFocused, isSelected } = props;
    const styles = getStyles('valueContainer', props);
    useEffect(() => {
        console.log("Children:", children, innerProps)
    }, [children])
    return (
        <div
            ref={innerRef}
            className={cx(
                css(styles),
            )}
            style={{
                display:"flex",
                flexDirection:"row",
                justifyContent:"flex-start",
                alignItems:"center",
                fontSize:"18px",
                maxWidth:"70%",
                overflow:"hidden",
                backgroundColor: isSelected ? "var(--background-2)" : isFocused ?   "var(--background-3)" : "var(--background)"}}

            {...innerProps}
        >
                  {children}  
        </div>
    );
};

export default LLMSelectSingleValueContainer;