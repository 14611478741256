import { FaPlusCircle, FaTimes, FaInfoCircle } from "react-icons/fa";
import { useEffect, useState, useRef } from "react";
import Select from "react-select";
import APITile from "./APITile";
import Switch from 'react-switch';
import { Tooltip } from 'react-tooltip';
import LLMIcon from "../../../Components/Icons/LLMIcon";
import { Circles } from 'react-loader-spinner';

export default function APISettings({ userData, companyLLMs, personalLLMs, onAvailableAPIsChanged, apiURL, securityLevels }) {
    const [newAPIMode, setNewAPIMode] = useState(null);
    const [newAPIName, setNewAPIName] = useState("");
    const [newAPIModelName, setNewAPIModelName] = useState("");
    const [newAPIURL, setNewAPIURL] = useState("");
    const [newAPIKey, setNewAPIKey] = useState("");
    const [newAPISecLevel, setNewAPISecLevel] = useState(null);
    const [newAPIContingentType, setNewAPIContingentType] = useState(false);
    const [newAPIIconNumber, setNewAPIIconNumber] = useState(0);
    const [securityLevelOptions, setSecurityLevelOptions] = useState([]);

    const [isInAddAPICall, setIsInAddAPICall] = useState(false);
    const [isChangeIconMode, setIsChangeIconMode] = useState(false);
    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsChangeIconMode(false);
            }
        };

        if (isChangeIconMode) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isChangeIconMode]);





    useEffect(() => {
        console.log("New sec levels")
        if (securityLevels) {
            const options = securityLevels.map(sec_level => {
                return {
                    label: sec_level.security_level_name,
                    value: sec_level.security_level
                }
            });
            console.log("Setting sec level for new api")
            setSecurityLevelOptions(options)
            setNewAPISecLevel(options[0])
        }
    }, [securityLevels])


    useEffect(() => {
        console.log("New API Mode:", newAPIMode);
        setNewAPIName("");
        setNewAPIURL("");
        setNewAPIModelName("");
        setNewAPIKey("");
    }, [newAPIMode])




    async function postNewAPIAccess(isCompanyAPI) {
        console.log("Posting new API access with iscompanyapi:", isCompanyAPI);
        setIsInAddAPICall(true);
        try {
            const response = await fetch(
                apiURL + "/add_llm_api?is_company_api=" + isCompanyAPI,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(
                        {
                            "api_id": -1,
                            "api_name": newAPIName,
                            "api_model_name": newAPIModelName,
                            "is_company_api": isCompanyAPI,
                            "api_url": newAPIURL,
                            "api_key": newAPIKey,
                            "security_level": isCompanyAPI ? newAPISecLevel.value : 1,
                            "contingentType": isCompanyAPI ? newAPIContingentType : false,
                            "icon_number": newAPIIconNumber
                        }
                    )
                }
            );

            if (response.status !== 200) {
                setIsInAddAPICall(false);
                console.log("Response status: " + response.status);
            } else {
                setIsInAddAPICall(false);
                setNewAPIMode(null);
                setNewAPIKey("");
                setNewAPIName("");
                setNewAPIURL("");
                setNewAPIModelName("");
                setTimeout(() => {
                    onAvailableAPIsChanged();
                }, 200);
            }
        } catch (error) {
            setIsInAddAPICall(false);
            console.log(error);
            alert("Error: " + error);
        }
    }

    return (
        <div className="subsetting-content">
            <center><h4>API Access</h4></center>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                {/* COMPANY  */}
                <h5>Company API Access</h5>

                {newAPIMode === "company" && userData.userRole <= 1 ?
                    <div className="setting-add-element-container">
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'flex-start', width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', flex: '3' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%' }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <div style={{ fontSize: '15px', marginRight: "5px" }}>API Name</div>
                                        <div data-tooltip-id="NLLMAPINTooltip">
                                            <FaInfoCircle />
                                        </div>
                                        <Tooltip id="NLLMAPINTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                The name this API will be refered to in further settings.
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <input
                                        className="settings-input"
                                        value={newAPIName}
                                        placeholder="New API Name"
                                        onChange={(e) => {
                                            setNewAPIName(e.target.value);
                                        }}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%' }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <div style={{ fontSize: '15px', marginRight: "5px" }}>API URL</div>
                                        <div data-tooltip-id="NLLMAPIUTooltip">
                                            <FaInfoCircle />
                                        </div>
                                        <Tooltip id="NLLMAPIUTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                The URL of the API that is used. Example for ChatGPT: https://api.openai.com/v1/chat/completions
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <input
                                        className="settings-input"
                                        value={newAPIURL}
                                        placeholder="New API URL"
                                        onChange={(e) => {
                                            setNewAPIURL(e.target.value);
                                        }}
                                    />

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%' }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <div style={{ fontSize: '15px', marginRight: "5px" }}>API Security Level</div>
                                        <div data-tooltip-id="NLLMAPISLTooltip">
                                            <FaInfoCircle />
                                        </div>
                                        <Tooltip id="NLLMAPISLTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                The Security Level for this API. Requests for this API will be chacked according to the Rules and Policys assigned to the Security Level. If no Security Level is selected, the default Security Level will be assigned.
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <Select
                                        styles={
                                            {
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                }),
                                                menu: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                }),
                                                option: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                }),
                                                valueContainer: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                }),
                                                singleValue: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                }),
                                                multiValue: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                }),
                                                multiValueLabel: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                }),
                                                container: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    width: "100%",
                                                    margin: "5px"
                                                })
                                            }}
                                        options={securityLevelOptions}
                                        onChange={(selectedOptions) => {
                                            console.log("selectedOptions:", selectedOptions);
                                            setNewAPISecLevel(selectedOptions);
                                        }}
                                        value={securityLevelOptions.find(ele => ele.value === newAPISecLevel)}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', flex: '3' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%', flex: '1' }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <div style={{ fontSize: '15px', marginRight: "5px" }}>API Model Name</div>
                                        <div data-tooltip-id="NLLMAPIMNTooltip">
                                            <FaInfoCircle />
                                        </div>
                                        <Tooltip id="NLLMAPIMNTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                Most Large Language Model provider give access to different models which can be specified by the model name. Example for ChatGPT: gpt-3.5-turbo
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <input
                                        className="settings-input"
                                        value={newAPIModelName}
                                        placeholder="New API Model Name"
                                        onChange={(e) => {
                                            setNewAPIModelName(e.target.value);
                                        }}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%', flex: '1' }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <div style={{ fontSize: '15px', marginRight: "5px" }}>API Key</div>
                                        <div data-tooltip-id="NLLMAPIKTooltip">
                                            <FaInfoCircle />
                                        </div>
                                        <Tooltip id="NLLMAPIKTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                The API can key can be empty for APIs without authorization. If a value is assigned it can only be accessed by system Admins.
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <input
                                        className="settings-input"
                                        value={newAPIKey}
                                        placeholder="New API Key"
                                        onChange={(e) => {
                                            setNewAPIKey(e.target.value);
                                        }}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%', flex: '1' }}>
                                    <label style={{ fontSize: '15px' }}>API Contingent Type</label>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
                                        <div style={{ marginRight: "10px", flex: "2", fontWeight: "normal" }}>{newAPIContingentType ? "Contingent per token" : "Contingent per request"}</div>
                                        <div style={{ flex: "1" }}>
                                            <Switch
                                                checked={newAPIContingentType}
                                                onChange={() => {
                                                    setNewAPIContingentType(!newAPIContingentType)
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: '1', height: "100%", marginRight: "20px" }}>
                                <LLMIcon
                                    iconNumber={newAPIIconNumber}
                                    maxHeight="100px"
                                    maxWidth="100px"
                                />
                                <div
                                    className="settings-button"
                                    onClick={() => {
                                        setIsChangeIconMode(true);
                                    }}>
                                    Change Icon
                                </div>
                                {
                                    isChangeIconMode &&
                                    <div style={{ position: "relative" }}>

                                        <div style={{
                                            position: "absolute",
                                            padding: "20px",
                                            border: "3px solid var(--text-icons-3)",
                                            borderRadius: "15px",
                                            display: "flex",
                                            flexDirection: "column",
                                            backgroundColor: "var(--background)",
                                            zIndex: "99999999",
                                            overflow: "auto",
                                            right: "-100px",
                                            top: "0"
                                        }}
                                            ref={popupRef}>
                                            {[0, 1, 2, 3].map(index =>
                                                <div
                                                    key={index}
                                                    style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                    <div style={{ margin: "10px", cursor: "pointer" }}
                                                        onClick={() => {
                                                            setNewAPIIconNumber(index * 2)
                                                            setIsChangeIconMode(false);
                                                        }}>
                                                        <LLMIcon
                                                            iconNumber={index * 2}
                                                            maxHeight="50px"
                                                            maxWidth="50px"
                                                        />
                                                    </div>
                                                    <div style={{ margin: "10px", cursor: "pointer" }}
                                                        onClick={() => {
                                                            setNewAPIIconNumber(index * 2 + 1)
                                                            setIsChangeIconMode(false);
                                                        }}>
                                                        <LLMIcon
                                                            iconNumber={index * 2 + 1}
                                                            maxHeight="50px"
                                                            maxWidth="50px"
                                                        />
                                                    </div>

                                                </div>
                                            )}
                                            Select your icon
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {/* New Company API Footer */}
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <div
                                className="settings-button"
                                onClick={() => {
                                    setNewAPIMode(false);
                                }}>
                                Cancel
                                <FaTimes className="cancel-delete-icon" />
                            </div>
                            <div
                                className="settings-button"
                                onClick={isInAddAPICall ? ()=> {} : () => {
                                    postNewAPIAccess(true)
                                }}>
                                Add API Access
                                {!isInAddAPICall ?
                                    <FaPlusCircle className="confirm-icon" />
                                    :
                                    <Circles
                                        height="20"
                                        width="20"
                                        color="var(--text-icons)"
                                        ariaLabel="circles-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                }
                            </div>
                        </div>
                    </div> : userData.userRole <= 1 ?
                        <div>
                            <div
                                className="settings-button"
                                onClick={() => {
                                    setNewAPIMode("company")
                                }}
                                data-tooltip-id="AddCompanyAccessHint"
                            >
                                Add new Company LLM API Access
                                <FaPlusCircle className="add-element-icon" />
                            </div>
                            <Tooltip id="AddCompanyAccessHint" delayShow={800} className="custom-tooltip">
                                <div style={{ maxWidth: "20vw", zIndex: "100" }}>
                                    Here you can define the URL the API key and other Parameters for a large language model. To use this API a user has to be authorized personally or hast to be in a group that has been authorized.
                                </div>
                            </Tooltip>
                        </div> : <></>}

                {companyLLMs &&
                    <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                        {/* ADD MAPPING OF APIS HERE */}
                        {companyLLMs.map((llm, index) => (
                            <APITile
                                key={"companyLLM" + index}
                                apiURL={apiURL}
                                onAPIAccessChanged={() => {
                                    onAvailableAPIsChanged();
                                }}
                                apiAccessData={llm}
                                isAdminView={userData.userRole <= 1}
                                securityLevelOptions={securityLevelOptions}
                            />
                        ))}
                    </div>
                }

                {/* PERSONAL */}
                <h5>Personal API Access</h5>
                {newAPIMode === "personal" ?
                    <div className="setting-add-element-container">
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'flex-start', width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', flex: '3' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%' }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <div style={{ fontSize: '15px', marginRight: "5px" }}>API Name</div>
                                        <div data-tooltip-id="NLLMAPINTooltip">
                                            <FaInfoCircle />
                                        </div>
                                        <Tooltip id="NLLMAPINTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                The name this API will be refered to in further settings.
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <input
                                        className="settings-input"
                                        value={newAPIName}
                                        placeholder="New API Name"
                                        onChange={(e) => {
                                            setNewAPIName(e.target.value);
                                        }}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%' }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <div style={{ fontSize: '15px', marginRight: "5px" }}>API URL</div>
                                        <div data-tooltip-id="NLLMAPIUTooltip">
                                            <FaInfoCircle />
                                        </div>
                                        <Tooltip id="NLLMAPIUTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                The URL of the API that is used. Example for ChatGPT: https://api.openai.com/v1/chat/completions
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <input
                                        className="settings-input"
                                        value={newAPIURL}
                                        placeholder="New API URL"
                                        onChange={(e) => {
                                            setNewAPIURL(e.target.value);
                                        }}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%' }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <div style={{ fontSize: '15px', marginRight: "5px" }}>API Security Level</div>
                                        <div data-tooltip-id="NLLMAPISLTooltip">
                                            <FaInfoCircle />
                                        </div>
                                        <Tooltip id="NLLMAPISLTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                The Security Level for this API. Requests for this API will be chacked according to the Rules and Policys assigned to the Security Level. Personal APIs con only use the default Security Level.
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <Select
                                        isDisabled={true}
                                        styles={
                                            {
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                }),
                                                menu: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                }),
                                                option: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                }),
                                                valueContainer: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                }),
                                                singleValue: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                }),
                                                multiValue: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                }),
                                                multiValueLabel: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                }),
                                                container: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    width: "100%",
                                                    margin: "5px"
                                                })
                                            }}
                                        options={securityLevelOptions}
                                        value={securityLevelOptions.find(ele => ele.value === 1) ? securityLevelOptions.find(ele => ele.value === 1) : { "label": "Default Security Level", "value": 1 }}
                                    />
                                    <div style={{ textAlign: "center", color: "var(--icons-text", fontSize: "12px" }}>
                                        Personal APIs can only use the default security level
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', flex: '3' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%', flex: '1' }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <div style={{ fontSize: '15px', marginRight: "5px" }}>API Model Name</div>
                                        <div data-tooltip-id="NLLMAPIMNTooltip">
                                            <FaInfoCircle />
                                        </div>
                                        <Tooltip id="NLLMAPIMNTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                Most Large Language Model provider give access to different models which can be specified by the model name. Example for ChatGPT: gpt-3.5-turbo
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <input
                                        className="settings-input"
                                        value={newAPIModelName}
                                        placeholder="New API Model Name"
                                        onChange={(e) => {
                                            setNewAPIModelName(e.target.value);
                                        }}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', width: '90%', flex: '1' }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <div style={{ fontSize: '15px', marginRight: "5px" }}>API Key</div>
                                        <div data-tooltip-id="NLLMAPIKTooltip">
                                            <FaInfoCircle />
                                        </div>
                                        <Tooltip id="NLLMAPIKTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                The API can key can be empty for APIs without authorization. For private APIs the value can only be accessed by the user that added the API.
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <input
                                        className="settings-input"
                                        value={newAPIKey}
                                        placeholder="New API Key"
                                        onChange={(e) => {
                                            setNewAPIKey(e.target.value);
                                        }}
                                    />
                                </div>

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: '1', height: "100%", marginRight: "20px" }}>
                                <LLMIcon
                                    iconNumber={newAPIIconNumber}
                                    maxHeight="100px"
                                    maxWidth="100px"
                                />
                                <div
                                    className="settings-button"
                                    onClick={() => {
                                        setIsChangeIconMode(true);
                                    }}>
                                    Change Icon
                                </div>
                                {
                                    isChangeIconMode &&
                                    <div style={{ position: "relative" }}>

                                        <div style={{
                                            position: "absolute",
                                            padding: "20px",
                                            border: "3px solid var(--text-icons-3)",
                                            borderRadius: "15px",
                                            display: "flex",
                                            flexDirection: "column",
                                            backgroundColor: "var(--background)",
                                            zIndex: "99999999",
                                            overflow: "auto",
                                            right: "-100px",
                                            top: "0"
                                        }}
                                            ref={popupRef}>
                                            {[0, 1, 2, 3].map(index =>
                                                <div
                                                    key={index}
                                                    style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                    <div style={{ margin: "10px", cursor: "pointer" }}
                                                        onClick={() => {
                                                            setNewAPIIconNumber(index * 2)
                                                            setIsChangeIconMode(false);
                                                        }}>
                                                        <LLMIcon
                                                            iconNumber={index * 2}
                                                            maxHeight="50px"
                                                            maxWidth="50px"
                                                        />
                                                    </div>
                                                    <div style={{ margin: "10px", cursor: "pointer" }}
                                                        onClick={() => {
                                                            setNewAPIIconNumber(index * 2 + 1)
                                                            setIsChangeIconMode(false);
                                                        }}>
                                                        <LLMIcon
                                                            iconNumber={index * 2 + 1}
                                                            maxHeight="50px"
                                                            maxWidth="50px"
                                                        />
                                                    </div>

                                                </div>
                                            )}
                                            Select your icon
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <div
                                className="settings-button"
                                onClick={() => {
                                    setNewAPIMode(false);
                                }}>
                                Cancel
                                <FaTimes className="cancel-delete-icon" />
                            </div>
                            <div
                                className="settings-button"
                                onClick={isInAddAPICall ? ()=> {} : () => {
                                    postNewAPIAccess(false)
                                }}>
                                Add API Access
                                {!isInAddAPICall ?
                                    <FaPlusCircle className="confirm-icon" />
                                    :
                                    <Circles
                                        height="20"
                                        width="20"
                                        color="var(--text-icons)"
                                        ariaLabel="circles-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                }
                            </div>
                        </div>
                    </div> :
                    <div
                        className="settings-button"
                        onClick={() => {
                            setNewAPIMode("personal")
                        }}
                        data-tooltip-id="AddPersonalAccessHint"
                    >
                        Add new Personal LLM API Acces
                        <FaPlusCircle className="add-element-icon" />
                        <Tooltip id="AddPersonalAccessHint" delayShow={800} className="custom-tooltip">
                            <div style={{ maxWidth: "20vw" }}>
                                A personal API access is only visible and editable for the User that created it. Personal API accesses always use the default security level and have no restraints regarding the amount of prompts.
                            </div>
                        </Tooltip>
                    </div>}
                {personalLLMs &&
                    <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                        {/* ADD MAPPING OF APIS HERE */}
                        {personalLLMs.map((llm, index) => (
                            <APITile
                                key={"privateLLM" + index}
                                apiURL={apiURL}
                                onAPIAccessChanged={() => {
                                    onAvailableAPIsChanged();
                                }}
                                apiAccessData={llm}
                                isAdminView={true}
                                securityLevelOptions={securityLevelOptions}
                            />
                        ))}
                    </div>
                }
            </div>
        </div>
    );
}
