import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import ChatGPTIcon from '../../Components/Icons/ChatGPTIcon';
import { FaUser, FaRegCopy, FaFileImport } from 'react-icons/fa';
import Quill from "quill";
import "quill/dist/quill.bubble.css";
import AudioController from './AudioController';
import { Document, Packer, Paragraph, patchDocument, PatchType, TextRun, Tab, Header, AlignmentType, Table, TableRow, TableCell, Media } from "docx";
import PrivcyPromptLogo from './../../assets/privacyprompt.svg'
import { Tooltip } from "react-tooltip";
import Select from "react-select";
import LLMIcon from '../../Components/Icons/LLMIcon';

const ChatEntry = forwardRef(({ apiURL, userData, source, text, chatIndex, model }, ref) => {
    const editorRef = useRef(null);
    const textContainerRef = useRef(null);
    const audioControlllerRef = useRef(null);
    const [isTemplateSelection, setIsTemplateSelction] = useState(false);
    const options = {
        readOnly: true,
        theme: 'bubble',
        modules: {
            toolbar: false,
            clipboard: {
                matchVisual: false,
            },
        },
    };

    useEffect(() => {
        console.log("Recived Source:", source, " Model:", model)
    }, [source, model])

    useEffect(() => {
        const textContainer = textContainerRef.current;
        if (!editorRef.current) {
            editorRef.current = new Quill(textContainer, options);
        }

        if (text) {
            editorRef.current.clipboard.dangerouslyPasteHTML(formatText(text));
        }
    }, [text, editorRef, textContainerRef]);

    useImperativeHandle(ref, () => ({
        readAloud() {
            if (audioControlllerRef) {
                audioControlllerRef.current.autoReadAlaoud();
            } else {
                console.log("AudioControllerRef not set")
            }
        }
    }));

    const formatText = (text) => {
        if (!source) {
            return text.replace(/\n/g, '<br>');
        }
        // Formatting functions as before
        const boldRegex = /\*\*(.*?)\*\*/g;
        const boldText = text.replace(boldRegex, (match, p1) => `<strong>${p1}</strong>`);

        const italicRegex = /_([\s\S]*?)_/g;
        const italicedText = boldText.replace(italicRegex, (match, p1) => `<i>${p1}</i>`);

        const codeRegex = /```([\s\S]*?)```/g;
        const codedtext = italicedText.replace(codeRegex, (match, p1) => `<pre><code>${p1}</code></pre>`);

        const blockCodeRegex = /`([\s\S]*?)`/g;
        const blockCodedText = codedtext.replace(blockCodeRegex, (match, p1) => `<code style="background-color: var(--background-3); padding: 2px 4px; border-radius: 4px;">${p1}</code>`);

        const strikeThroughRegex = /~(.*?)~/g;
        const strikedThroughText = blockCodedText.replace(strikeThroughRegex, (match, p1) => `<s>${p1}</s>`);

        const majorHeaderRegEx = /###([\s\S]*?)\n/g;
        const majorHeadedText = strikedThroughText.replace(majorHeaderRegEx, (match, p1) => `<h5>${p1}</h5>`);

        const mediumHeaderRegEx = /##([\s\S]*?)\n/g;
        const mediumHeadedText = majorHeadedText.replace(mediumHeaderRegEx, (match, p1) => `<h3>${p1}</h3>`);

        const minorHeaderRegEx = /#([\s\S]*?)\n/g;
        const minorHeadedText = mediumHeadedText.replace(minorHeaderRegEx, (match, p1) => `<h1>${p1}</h1>`);

        const lines = minorHeadedText.split('\n');
        let html = '';
        let inOrderedList = false;
        let inUnorderedList = false;

        lines.forEach((line) => {
            if (line.match(/^\s*\d+\.\s/)) {
                if (!inOrderedList) {
                    html += '<ol>';
                    inOrderedList = true;
                }
                const listItem = line.replace(/^\s*\d+\.\s/, '').trim();
                html += `<li>${listItem}</li>`;
            } else if (line.startsWith('*') || line.startsWith('t*')) {
                if (!inUnorderedList) {
                    html += '<ul>';
                    inUnorderedList = true;
                }
                const listItem = line.replace(/^\s*[\t*]\s?/, '').trim();
                html += `<li>${listItem}</li>`;
            } else {
                if (inOrderedList) {
                    html += '</ol>';
                    inOrderedList = false;
                }
                if (inUnorderedList) {
                    html += '</ul>';
                    inUnorderedList = false;
                }
                html += line + '<br>';
            }
        });

        if (inOrderedList) {
            html += '</ol>';
        }
        if (inUnorderedList) {
            html += '</ul>';
        }

        return html;
    };

    const formatTextCopy = (text) => {
        // Simplified version of formatText for clipboard copy
        const boldRegex = /\*\*(.*?)\*\*/g;
        const boldText = text.replace(boldRegex, (match, p1) => `${p1}`);

        const italicRegex = /_([\s\S]*?)_/g;
        const italicedText = boldText.replace(italicRegex, (match, p1) => `${p1}`);

        const codeRegex = /```([\s\S]*?)```/g;
        const codedtext = italicedText.replace(codeRegex, (match, p1) => `${p1}`);

        const blockCodeRegex = /`([\s\S]*?)`/g;
        const blockCodedText = codedtext.replace(blockCodeRegex, (match, p1) => `${p1}`);

        const strikeThroughRegex = /~(.*?)~/g;
        const strikedThroughText = blockCodedText.replace(strikeThroughRegex, (match, p1) => `${p1}`);

        const majorHeaderRegEx = /###([\s\S]*?)\n/g;
        const majorHeadedText = strikedThroughText.replace(majorHeaderRegEx, (match, p1) => `${p1}`);

        const mediumHeaderRegEx = /##([\s\S]*?)\n/g;
        const mediumHeadedText = majorHeadedText.replace(mediumHeaderRegEx, (match, p1) => `${p1}`);

        const minorHeaderRegEx = /#([\s\S]*?)\n/g;
        const minorHeadedText = mediumHeadedText.replace(minorHeaderRegEx, (match, p1) => `${p1}`);

        const lines = minorHeadedText.split('\n');
        let html = '';
        let inOrderedList = false;
        let inUnorderedList = false;

        lines.forEach((line) => {
            if (line.match(/^\s*\d+\.\s/)) {
                if (!inOrderedList) {
                    html += '';
                    inOrderedList = true;
                }
                const listItem = line.replace(/^\s*\d+\.\s/, '').trim();
                html += `${listItem}\n`;
            } else if (line.startsWith('*') || line.startsWith('t*')) {
                if (!inUnorderedList) {
                    html += '';
                    inUnorderedList = true;
                }
                const listItem = line.replace(/^\s*[\t*]\s?/, '').trim();
                html += `${listItem}\n`;
            } else {
                if (inOrderedList) {
                    html += '\n';
                    inOrderedList = false;
                }
                if (inUnorderedList) {
                    html += '\n';
                    inUnorderedList = false;
                }
                html += line + '\n';
            }
        });

        if (inOrderedList) {
            html += '\n';
        }
        if (inUnorderedList) {
            html += '\n';
        }

        return html;
    };

    const copyToClipboard = (text) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    console.log('Text wurde in die Zwischenablage kopiert');
                })
                .catch((err) => {
                    console.error('Fehler beim Kopieren des Textes:', err);
                });
        } else {
            fallbackCopyToClipboard(text);
        }
    };

    const fallbackCopyToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.position = 'fixed';  // Sicherstellen, dass es unsichtbar ist, aber auswählbar
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'Text wurde in die Zwischenablage kopiert' : 'Fehler beim Kopieren des Textes';
            console.log(msg);
        } catch (err) {
            console.error('Fallback: Fehler beim Kopieren', err);
        }

        document.body.removeChild(textArea);
    };


    async function createAndDownloadDocument(templateFileName) {
        console.log("Into create and download Document with Template:", templateFileName)
        try {
            const response = await fetch(
                apiURL + "/write_text_into_template?templateName=" + templateFileName + "&textToWrite=" + formatTextCopy(text),
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'edited_template.docx';  // Adjust the name as needed
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', width: "100%" }} ref={ref}>
            <div style={{flex:"1"}}>
                {
                    source ?
                        <div style={{ margin: "8px", width: "30px", height: "30px" }}>
                            { model && 
                            <LLMIcon
                                iconNumber={model.icon_number}
                                maxHeight='25px'
                                maxWidth='25px'
                            />
}
                        </div> :
                        <div style={{width:"30px", height:"30px", margin: '8px' }}>
                            <FaUser style={{fontSize:"larger", }} />
                        </div>
                        
                }
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flex:"20"}}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ fontWeight: 'bold', marginTop: '4px' }}> {source ? model ? model.api_name: "Model not available" : "You"} </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        {userData.template_files.length > 0 &&
                            <div>
                                {!isTemplateSelection ?
                                    <FaFileImport style={{ margin: "5px", cursor: "pointer" }}
                                        data-tooltip-id={"DownloadDocTooltip" + chatIndex}
                                        onClick={() => {
                                            if (userData.template_files.length > 1) {
                                                setIsTemplateSelction(true);
                                            } else {
                                                createAndDownloadDocument(userData.template_files[0])
                                            }
                                        }} />
                                    :
                                    <div style={{ marginRight: "10px" }}>
                                        <Select
                                            styles={
                                                {
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                    }),
                                                    menu: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                    }),
                                                    option: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                    }),
                                                    valueContainer: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                    }),
                                                    singleValue: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                    }),
                                                    multiValue: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                    }),
                                                    multiValueLabel: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "var(--text-icons)",
                                                        backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                                    }),
                                                    container: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        width: "100%",
                                                        margin: "5px"
                                                    })
                                                }}
                                            options={userData.template_files.map(template_file => ({ label: template_file, value: template_file }))}
                                            getOptionLabel={(template_file) => template_file.label}
                                            getOptionValue={(template_file) => template_file.value}
                                            onChange={(selectedOptions) => {
                                                console.log("Selected option for Template:", selectedOptions)
                                                setIsTemplateSelction(false);
                                                createAndDownloadDocument(selectedOptions.value)
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        }
                        <AudioController textToRead={formatTextCopy(text)} ref={audioControlllerRef} />
                        <FaRegCopy style={{ margin: '5px', cursor: 'pointer' }} onClick={() => {
                            copyToClipboard(formatTextCopy(text))
                        }}
                            data-tooltip-id={"CopyClipTooltip" + chatIndex} />
                    </div>
                </div>
                <div
                    ref={textContainerRef}
                    id={source + chatIndex}
                    className="chat-text">

                </div>
            </div>
            <Tooltip id={"DownloadDocTooltip" + chatIndex} className="custom-tooltip" delayShow={800}>
                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                    Here you can download this message into one of your prepared template documents.
                </div>
            </Tooltip>

            <Tooltip id={"CopyClipTooltip" + chatIndex} className="custom-tooltip" delayShow={800}>
                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                    Copy this message to your clipboard.
                </div>
            </Tooltip>
        </div>
    );
});

export default ChatEntry

