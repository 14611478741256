import { useEffect, useState } from 'react';
import './Impressum.css'
import { Link } from 'react-router-dom';
import { CompanyData, UserData } from '../Settings/SettingsTypedefs';
import privacyprompt from './../../assets/privacyprompt.svg';
import reutersLogo from './../../assets/reuters.svg'
import NYTLogo from './../../assets/NYT.svg'
import BBCLogo from './../../assets/bbc.svg'
import CNNLogo from './../../assets/cnn.svg'
import HSLogo from './../../assets/logo_hochschule_mannheim_farbe.svg'
import InstitutLogo from './../../assets/color_with_background.svg'
import ChatExample from './../../assets/ChatExample.png';
import AccountDropdown from './AccountDropdown';
import APISetupDropdown from './APISetupDropdown';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import ArticleCarouselTile from './ArticleCarouselTile';


function Impressum({ apiUrl, companyData, userData, onLogout }: { apiUrl: string, companyData: CompanyData, userData: UserData, onLogout: Function }) {
    const [isAPIAlive, setIsAPIAlive] = useState(false);
    const [isAPIInitalized, setIsAPIInitalized] = useState(false)

    useEffect(() => {
        testAPIAlive();
    }, [])

    useEffect(() => {
        testAPIAlive();
    }, [apiUrl])

    async function testAPIAlive() {
        if (apiUrl) {
            try {
                const response = await fetch(
                    apiUrl + "/test_alive",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.status !== 200) {
                    console.log("Response status: " + response.status);
                } else {
                    const data = await response.json();
                    setIsAPIAlive(true);
                    setIsAPIInitalized(data.initalized);
                }
            } catch (error) {
                //window.location.href = "/login";
                console.log(error);
                //alert("Error: " + error);
            }
        }
    }

    return (
        <div className="impressum-container">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center", height: "80px" }}>
                <div style={{ flex: "1", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                    <img src={privacyprompt} alt="PrivacyPrompt Logo" style={{ maxHeight: '60px', maxWidth: '50vw', marginLeft: "20px" }} />
                    <div style={{ fontSize: "25px", fontWeight: "bolder" }}>Privacy Prompt</div>
                </div>
                <div style={{ flex: "1", display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                    <APISetupDropdown
                        userData={userData}
                        companyData={companyData}
                        isAlive={isAPIAlive}
                        isInitialized={isAPIInitalized}
                    />
                    <AccountDropdown
                        userData={userData}
                        companyData={companyData}
                        onLogout={() => {
                            onLogout();
                        }}
                    />
                </div>
            </div>
            {/*
            <Carousel
                additionalTransfrom={0}
                arrows={true}
                autoPlay
                autoPlaySpeed={5000}
                centerMode={false}
                className=""
                containerClass="container-padding-bottom"
                dotListClass=""
                draggable
                focusOnSelect
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 400
                        },
                        items: 1,
                        partialVisibilityGutter: 40
                    }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >


                <div className='impressum-carusel-tile'>
                    <div style={{ flex: "4", margin: "20px" }}>
                        <img src={ChatExample} alt="Chat Example" style={{ maxHeight: "50vh", width: 'auto', display: 'block' }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", flex: "3" }}>
                        <div style={{ width: "80%", marginTop: "70px" }}>
                            In recent years, OpenAI has revolutionized the field of large language models with ChatGPT.
                            Since then, the use of these neural networks has exploded.
                            However, there is a lack of awareness about how questions sent to these models are processed and what kind of data is collected by the providers.
                            This lack of awareness could result in numerous breaches of data protection laws,
                            as private and commercial users continue to use these services without realizing that involving a third party can lead to serious legal consequences.
                            With Privacy Prompt, we provide an interface to all large language models that checks questions sent to the models for private data and performs
                            anonymization of critical content. This ensures that no legally concerning data is sent to third-party model providers.

                        </div>
                        <h2>Upgrade your data protection concept</h2>
                        <div style={{ width: "80%", marginTop: "20px" }}>
                            <ul>
                                <li><strong>Keep up to Date:</strong> Use the newest large language models with confidence, knowing that critical information is safeguarded.</li>
                                <li><strong>Protect Sensitive Information:</strong> Ensure that private and sensitive data is not sent to third-party model providers.</li>
                                <li><strong>Compliance with Data Protection Laws:</strong> Avoid legal issues by anonymizing data and adhering to data protection regulations.</li>
                                <li><strong>Enhanced Data Security:</strong> Reduce the risk of data breaches by filtering and anonymizing the content before it leaves your system.</li>
                                <li><strong>Seamless Workflow:</strong> Easily integrate Privacy Prompt as most confidentially breaches can be anonymized without additional user input</li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className='impressum-carusel-tile'>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", flex: "5", margin: "20px" }}>
                        <h2>AI Content Check</h2>
                        We developed an Artifical Intelligence, which will check the content of each Prompt and will block Prompts, if the content can not be anonymizezd with satisfying results.
                        Only for these blocked prompts additional input of the user will be neccessary to provide an answer of the selected Large Language Model.
                        <div style={{ width: "80%", marginTop: "20px" }}>
                            <ul>
                                <li><strong>Specialized Network:</strong>
                                    Our team developed a network specialized in recognizing privcy concerning content. This network can be further customized by simple prompts to fine tune it to your requirements.
                                </li>
                                <li><strong>General Directives:</strong>
                                    Thanks to this specialized network, you can define genral directives. Our network will recognize violations of these directives and block the corresponding prompts.
                                </li>
                                <li><strong>Context Preservation:</strong>
                                    While anonymizing the content of critical prompt, Privacy Prompt uses aliases to preserve the given context of the prompt. While processing the the Models answer these aliases will be translated again so your users wont even realize a difference to an uncritical question.
                                </li>


                            </ul>
                        </div>
                    </div>
                    <div style={{ flex: "3", margin: "20px" }}>
                        <img src={ChatExample} alt="Chat Example" style={{ maxHeight: "50vh", width: 'auto', display: 'block' }} />
                    </div>
                </div>



                <div className='impressum-carusel-tile'>
                    <div style={{ flex: "4", margin: "20px" }}>
                        <img src={ChatExample} alt="Chat Example" style={{ maxHeight: "50vh", width: 'auto', display: 'block' }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", flex: "3" }}>
                        <h2>Work with multiple Models</h2>
                        Privacy Prompt was developed to work with multiple target models.
                        You can define accesses for your company and unlock them for users or groups of users.
                        Every model will have an assigned customizable security levlel.
                        If your company allows so, users will also be able to use Privacy Prompt for theire private API access with a default security level.
                        <div style={{ width: "80%", marginTop: "20px" }}>
                            <ul>
                                <li><strong>Use your own Model:</strong> You can use your own trained models with the Privacy Prompt API. The API is developed to handle all common Interfaces.</li>
                                <li><strong>Keep Access to the lates Models:</strong> Use the newest large language models with confidence, knowing that critical information is safeguarded. And with simple integration into Privacy Prompt.</li>
                                <li><strong>Train your Staff:</strong> Customize hints for your employees to train them to reccognize private data violiations on theire own</li>
                                <li><strong>Keep Controll over Access:</strong> Keep controll over the amount of the API usage with simply customizable contingents for user and models.</li>
                            </ul>
                        </div>
                    </div>
                </div>



                <div className='impressum-carusel-tile'>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", flex: "5", margin: "20px" }}>
                        <h2>Keep everything in your Network</h2>
                        <div style={{ width: "80%", marginTop: "20px" }}>
                            <ul>
                                <li><strong>Local Network:</strong> Privacy Prompt will be installed inside of your local network. This ensures, that requests will be processed and anonymized before leaving your network.</li>
                                <li><strong>No global Service:</strong> Your Privacy Prompt instance will be completly independent and wont relay on any global services. No data regarding your users activity will be accessible by us.</li>
                                <li><strong>Encryption:</strong> Communication between the clients and the Privacy Prompt API will be encrypted.</li>
                                <li><strong>Outgoing Requests:</strong> Every outgoing requests will be anonymized and checked by AI for private content. This will maximize the controll you have over the content of outgoing requests to Large Languge Models</li>
                                <li><strong>Customizable Security Levels:</strong> Customize the content which will be allowed in outgoing requests for each of your Large Language Model API</li>
                            </ul>
                        </div>
                    </div>
                    <div style={{ flex: "3", margin: "20px" }}>
                        <img src={ChatExample} alt="Chat Example" style={{ maxHeight: "50vh", width: 'auto', display: 'block' }} />
                    </div>

                </div>

            </Carousel>
           */}

            <center style={{ marginTop: "20px" }}><h2>Related Articles</h2></center>
            <div>
                <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass="impressum-carusel-tile"
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 3,
                            partialVisibilityGutter: 40
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 1,
                            partialVisibilityGutter: 30
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 2,
                            partialVisibilityGutter: 30
                        }
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >
                    <ArticleCarouselTile
                        articleText="Under the EU's General Data Protection Regulation (GDPR) introduced in 2018, any company found to have broken rules faces fines of up to 4% of its global turnover. In December, EU lawmakers and governments agreed provisional terms for regulating AI systems such as ChatGPT, moving a step closer to setting rules to govern the technology."
                        artikleImageSrc={reutersLogo}
                        artikleUrl="https://www.reuters.com/technology/cybersecurity/italy-regulator-notifies-openai-privacy-breaches-chatgpt-2024-01-29/"
                    />
                    <ArticleCarouselTile
                        articleText='“To the best of my knowledge, no commercially available large language models have strong defenses to protect privacy,” said Dr. Prateek Mittal, a professor in the department of electrical and computer engineering at Princeton University.'
                        artikleImageSrc={NYTLogo}
                        artikleUrl="https://www.nytimes.com/interactive/2023/12/22/technology/openai-chatgpt-privacy-exploit.html"
                    />
                    <ArticleCarouselTile
                        articleText='On social media sites Reddit and Twitter, users had shared images of chat histories that they said were not theirs. OpenAI CEO Sam Altman said the company feels "awful", but the "significant" error had now been fixed. Many users, however, remain concerned about privacy on the platform. Each conversation with the chatbot is stored in the users chat history bar where it can be revisited later.'
                        artikleImageSrc={BBCLogo}
                        artikleUrl="https://www.bbc.com/news/technology-65047304"
                    />
                    <ArticleCarouselTile
                        articleText="“You’ve got all these employees doing things which can seem very innocuous, like, ‘Oh, I can use this to summarize notes from a meeting,’” Mills said. “But in pasting the notes from the meeting into the prompt, you’re suddenly, potentially, disclosing a whole bunch of sensitive information.”"
                        artikleImageSrc={CNNLogo}
                        artikleUrl="https://edition.cnn.com/2023/04/06/tech/chatgpt-ai-privacy-concerns/index.html"
                    />
                </Carousel>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>

                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                    <h4>Our Partners:</h4>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                        <a className='partner-tile' href="https://www.hs-mannheim.de/">
                            <img src={HSLogo} alt="HS Mannheim Logo" style={{ height: "100px" }} />
                            Hochschule Mannheim
                        </a>
                        <a className='partner-tile' href="https://www.esm.hs-mannheim.de/institut.html">
                            <img src={InstitutLogo} alt="Institut A²IR Logo" style={{ height: "100px" }} />
                            Institute for Applied Artificial Intelligence and Robotics (A²IR)
                        </a>
                    </div>
                </div>
            </div>
                <div style={{ fontSize: "12px", display: "flex", flexDirection: "row", margin: "10px" }}>
                    <label>This website is developed and maintained by:
                         Institute for Applied Artificial Intelligence and Robotics (A²IR) 
                         Paul-Wittsack-Straße 10 
                         68163 Mannheim 
                         For issues regarding this website please Contact: 
                         Mail: m.vetter@hs-mannheim.de 
                         Tel: (06 21) 292-63 47
                    </label>
                </div>            
        </div>
    );
}

export default Impressum;