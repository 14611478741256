import { useRef, useState, useEffect } from 'react';
import { FaPlusCircle, FaTrash, FaAngleUp, FaAngleDown, FaTimes, FaEdit, FaCheck, FaInfoCircle } from "react-icons/fa";
import AnimateHeight, { Height } from "react-animate-height"
import { PrivacyRule, SecuredDataTable } from '../SettingsTypedefs';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRepeat, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";




export default function SecuredTableTile({ apiURL, securedTable, securityRules, onTableChanged, ...props }:
    { apiURL: string, securedTable: SecuredDataTable, securityRules: PrivacyRule[], onTableChanged: Function }) {
    const [expanded, setExpanded] = useState(false)
    const [height, setHeight] = useState<Height>('auto');
    const contentDiv = useRef<HTMLDivElement | null>(null);
    const updateInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const element = contentDiv.current as HTMLDivElement;

        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
    }, []);


    async function deleteTable() {
        try {
            const response = await fetch(
                apiURL + "/delete_secure_data_table?tableID=" + securedTable.tableID,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                onTableChanged();
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function updateSecureDataTable(file: File) {
        try {
            const formData = new FormData();
            formData.append('secure_data_file', file);
            const response = await fetch(
                apiURL + "/update_secure_data_table?tableID=" + securedTable.tableID,
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: formData,
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                onTableChanged();
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function postNewAlias(columnID: number, alias: string) {
        try {
            const response = await fetch(
                apiURL + "/add_column_alias",
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "columnID": columnID,
                        "alias": alias
                    }),
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                onTableChanged();
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }

    async function deleteAlias(aliasID:number) {
        try {
            const response = await fetch(
                apiURL + "/delete_column_alias?aliasID="+ aliasID,
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "application/json"
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                onTableChanged();
            }
        } catch (error) {
            console.log(error);
            //alert("Error: " + error);
        }
    }


    const handleUpdateInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("Handle File input Change")
        if (event.target.files && event.target.files.length == 1) {
            updateSecureDataTable(event.target.files[0])
        } else {
            console.log("ToDO Set Info Text Here")
        }
        event.target.value = '';
    }


    return (
        <AnimateHeight
            height={height}
            contentClassName="auto-content"
            contentRef={contentDiv}
            disableDisplayNone
            style={{ padding: "10px", width: "90%", border: "solid 1px var(--text-icons-2)", borderRadius: "10px", marginBottom: "10px", overflow: "visible" }}>
            <div ref={contentDiv}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", cursor: "pointer" }}
                    onClick={() => setExpanded(!expanded)}
                >   <div style={{ flex: "5" }}>
                        {securedTable.tableName}
                    </div>
                    <div style={{ flex: "2" }}>
                        Rows: {securedTable.rowCount}
                    </div>
                    <div style={{ flex: "2" }}>
                        Columns: {securedTable.columns.length}
                    </div>
                    <div style={{ flex: "2" }}>
                        Referenced by rules: {securityRules.filter(rule =>
                            rule.buckets.some(bucket =>
                                bucket.columns.some(bucketColumn =>
                                    securedTable.columns.some(securedColumn =>
                                        securedColumn.id === bucketColumn.columnID
                                    )
                                )
                            )
                        ).length}
                    </div>
                    <div style={{ flex: "1", display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end" }}>
                        {expanded ?
                            <FaAngleUp onClick={() => setExpanded(false)} style={{ cursor: 'pointer' }} /> :
                            <FaAngleDown />
                        }
                    </div>
                </div>
                <div style={expanded ? { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "15px", width: "100%" } :
                    { display: "none" }}>
                    <div style={{ width: "100%", borderTop: "solid 2px var(--gray-500)" }} />

                    <div style={{ display: "flex", flex: "1", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", width: "90%" }}>

                        <div style={{ fontWeight: 'bold', display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "95%" }}>
                            <div style={{ margin: "5px", fontWeight: 'bold', display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                <div style={{ marginRight: "5px" }}>Table Columns:</div>
                                <div data-tooltip-id="TCTooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                    <FaInfoCircle />
                                    <Tooltip id="TCTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            This are the names of the columns that are referenced by the security Rules.
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <div style={{ margin: "5px", fontWeight: 'bold', display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                <div style={{ marginRight: "5px" }}>Column Aliases:</div>
                                <div data-tooltip-id="CATooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                    <FaInfoCircle />
                                    <Tooltip id="CATooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            Here you can define Aliases for the Column. These Aliases will be send to the Large Language Models instead of the Matched Table entries. Better Aliases will lead to a better context understanding of the Models.
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <div style={{ margin: "5px", fontWeight: 'bold', display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                <div style={{ marginRight: "5px" }}>Rule Referencing:</div>
                                <div data-tooltip-id="RRTooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                    <FaInfoCircle />
                                    <Tooltip id="RRTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            Here the rules that are referencing each Column are shown. These can be edited in the Security Rules tab.
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>



                        {securedTable.columns.map((column) =>
                            <div key={"Column" + column.id}
                                style={{ width: "100%", border: "1px solid var(--text-icons-4)", padding: "5px", borderRadius: "5px", marginBottom: "5px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}
                            >
                                <div style={{ flex: "1", lineBreak: "anywhere" }}>
                                    {column.name}
                                </div>
                                <div style={{ flex: "2", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width:"100%" }}>
                                        <input
                                            id={"newAliasC" + column.id}
                                            type='text'
                                            placeholder='New alias'
                                            className='settings-input'
                                            style={{ width: "70%" }}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                    const inputElement = document.getElementById(`newAliasC${column.id}`) as HTMLInputElement;
                                                    if (inputElement && inputElement.value && inputElement.value !== "") {
                                                        postNewAlias(column.id, inputElement.value);
                                                        inputElement.value = "";
                                                    }
                                                }
                                            }}
                                        />
                                        <FaPlusCircle
                                            className="add-element-icon"
                                            data-tooltip-id={"NewAliasTooltip" + column.id}
                                            onClick={() => {
                                                const inputElement = document.getElementById(`newAliasC${column.id}`) as HTMLInputElement;
                                                if (inputElement && inputElement.value && inputElement.value !== "") {
                                                    postNewAlias(column.id, inputElement.value);
                                                    inputElement.value = "";
                                                }
                                            }} />
                                        <Tooltip id={"NewAliasTooltip" + column.id} className="custom-tooltip" delayShow={800}>
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                Here you can add a new alias for the Column that will be send to the Large Language Model API instead of the Table entries that were matched. Better aliases will lead to a better Context understanding of the Model.
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div style={{width:"80%"}}>
                                    {column.aliases && column.aliases.map((alias)=> 
                                        <div key={alias.id}
                                        style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", padding:"5px", borderTop:"solid 1px var(--gray-500)"}}>
                                            <div style={{maxWidth:"70%"}}>
                                                {alias.alias}
                                            </div>
                                            <FaTimes className='cancel-delete-icon'
                                            onClick={()=>{
                                                deleteAlias(alias.id!)
                                            }}/>
                                        </div>
                                    )}
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", marginRight: "15px", flex: "1" }}>
                                    {securityRules
                                        .filter(rule =>
                                            rule.buckets.some(bucket =>
                                                bucket.columns.some(Bcolumn => Bcolumn.columnID === column.id)
                                            )
                                        )
                                        .map((rule) =>
                                            <div key={rule.id}>
                                                {rule.name}
                                            </div>
                                        )}
                                </div>
                            </div>)}
                    </div>








                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <div
                            data-tooltip-id="DeleteTableTooltip"
                            className="settings-button"
                            onClick={() => {
                                deleteTable();
                            }}
                        >
                            Delete
                            <FontAwesomeIcon icon={faTrash} style={{ fontSize: "x-large", marginLeft: "15px", color: "red" }} />
                            <Tooltip id="DeleteTableTooltip" className="custom-tooltip" delayShow={800}>
                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                    Here you can delete the table. Be aware, that if the Table is still referenced by Rules, the Buckets referencing this Table will be removed from the Rule.
                                </div>
                            </Tooltip>
                        </div>
                        <div
                            data-tooltip-id="UpdateTableTooltip"
                            className="settings-button"
                            onClick={() => {
                                if (updateInputRef.current) {
                                    updateInputRef.current.click();
                                }
                            }}
                        >
                            Update
                            <FontAwesomeIcon icon={faRepeat} style={{ fontSize: "x-large", marginLeft: "15px", color: "blue" }} />
                            <Tooltip id="UpdateTableTooltip" className="custom-tooltip" delayShow={800}>
                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                    Here you can update the Table entries for an existing Table. The column names of the new table have to be persistent with the old table.
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <input
                    type="file"
                    ref={updateInputRef}
                    style={{ display: 'none' }}
                    onChange={handleUpdateInputChange}
                    accept='.csv'
                />
            </div>
        </AnimateHeight>
    );
}
