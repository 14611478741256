import { useRef, useState, useEffect } from 'react';
import { FaTrash, FaAngleUp, FaAngleDown, FaInfoCircle } from "react-icons/fa";
import AnimateHeight, { Height } from "react-animate-height"
import { PrivacyRule, Column, BucketColumn, SecuredDataTable } from '../SettingsTypedefs';
import BucketColumnTile from "./BucketColumnTile";
import { Tooltip } from "react-tooltip";

import Select from "react-select";
import Switch from 'react-switch';





export default function SecurityRuleTile({ apiURL, rule, onSecurityRulesChanged, securedTables, ...props }:
    { apiURL: string, rule: PrivacyRule, onSecurityRulesChanged: Function, securedTables: SecuredDataTable[] }) {
    const [ruleState, setRuleState] = useState<PrivacyRule>(rule)
    const [expanded, setExpanded] = useState(false)
    const [height, setHeight] = useState<Height>('auto');
    const contentDiv = useRef<HTMLDivElement | null>(null);
    type OptionType = {
        value: number;
        label: string;
    };
    const ruleTypeOptions: OptionType[] = [
        {
            value: 0,
            label: "Not in Chat History"

        },
        {
            value: 1,
            label: "Not in Chat Message"

        },
        {
            value: 2,
            label: "Not in Sentence"

        }
    ]
    useEffect(() => {
        const element = contentDiv.current as HTMLDivElement;

        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
    }, []);

    useEffect(() => {
        if (ruleState && rule !== ruleState) {
            rule = ruleState
            postUpdateSecurityRule();
            onSecurityRulesChanged();
        }
    }, [ruleState])

    useEffect(()=> {
        setRuleState(rule)
    }, [rule])

    async function deleteSecurityRule() {
        try {
            const response = await fetch(
                apiURL + "/delete_privacy_rule?ruleID=" + rule.id,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                console.log(data)
                onSecurityRulesChanged();
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function postUpdateSecurityRule() {
        try {
            const response = await fetch(
                apiURL + "/update_privacy_rule",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(ruleState)
                }
            );
            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json();
                console.log(data)
            }
        } catch (error) {
            console.log(error);
        }
    }



    return (
        <AnimateHeight
            height={height}
            contentClassName="auto-content"
            contentRef={contentDiv}
            disableDisplayNone
            style={{ padding: "10px", width: "90%", border: "solid 1px var(--text-icons-2)", borderRadius: "10px", marginBottom: "10px", overflow: "visible" }}>
            <div ref={contentDiv}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", cursor: "pointer" }}
                    onClick={() => setExpanded(!expanded)}
                >   <div style={{ flex: "5" }}>
                        {rule.name}
                    </div>
                    <div style={{ flex: "2" }}>
                        Buckets: {ruleState.buckets.length}
                    </div>
                    <div style={{ flex: "1", display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end" }}>
                        {expanded ?
                            <FaAngleUp onClick={() => setExpanded(false)} style={{ cursor: 'pointer' }} /> :
                            <FaAngleDown />
                        }
                    </div>
                </div>
                <div style={expanded ? { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "15px" } :
                    { display: "none", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>


                    <div style={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "90%", alignItems: "flex-start", justifyContent: "center" }}>
                            <div style={{ display: "flex", flex: "1", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                    <div style={{ margin: "5px", fontWeight: 'bold' }}>Security Rule Type</div>
                                    <div data-tooltip-id="SRTSRTTooltip">
                                        <FaInfoCircle />
                                        <Tooltip id="SRTSRTTooltip" className="custom-tooltip">
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                The Rule Type defines, how much of a conversation with a Large Language Model is checked before the prompt is sent to the API. For example, two messages which each contain part of a whole Bucket will fulfill the Rule if this value is set to "Not in Chat Message", but break the Rule if its set to "Not in Chat History"
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                                <Select
                                    styles={
                                        {
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                                backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                            }),
                                            menu: (baseStyles, state) => ({
                                                ...baseStyles,
                                                backgroundColor: "var(--background)",
                                                color: "var(--text-icons)"
                                            }),
                                            option: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: "var(--text-icons)",
                                                backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                            }),
                                            valueContainer: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: "var(--text-icons)",
                                                backgroundColor: "var(--background)"
                                            }),
                                            singleValue: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: "var(--text-icons)",
                                                backgroundColor: "var(--background)"
                                            }),
                                            multiValue: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: "var(--text-icons)",
                                                backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                            }),
                                            multiValueLabel: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: "var(--text-icons)",
                                                backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                            }),
                                            container: (baseStyles, state) => ({
                                                ...baseStyles,
                                                width: "100%",
                                                margin: "5px"
                                            })
                                        }}
                                    options={ruleTypeOptions}
                                    onChange={(selectedOptions) => {
                                        console.log("selectedOptions:", selectedOptions);
                                        console.log("selectedOptions VALUE:", selectedOptions!.value)
                                        rule = { ...rule, level: selectedOptions!.value }
                                        postUpdateSecurityRule();
                                        onSecurityRulesChanged();

                                    }}
                                    value={ruleTypeOptions.filter((option) => option.value == rule.level)[0]}
                                    isMulti={false}
                                    menuPortalTarget={document.body}
                                    menuPosition={'absolute'}
                                    menuPlacement={'auto'}
                                />
                            </div>
                        </div>



                        {ruleState.buckets.map((bucket, index) => (
                            <div
                                style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "center", width: "100%", borderTop: "solid 2px var(--gray-500)", marginTop: "20px", paddingTop: "20px", position: "relative", alignSelf: "center", justifySelf: "center" }}
                                key={"new_bucket" + index}>
                                <div style={{ display: "flex", flex: "3", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", marginBottom: "20px" }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                        <div style={{ margin: "5px", fontWeight: 'bold' }}>Security Rule Bucket</div>
                                        <div data-tooltip-id={"SRTooltipBucket:" + index} >
                                            <FaInfoCircle />
                                            <Tooltip id={"SRTooltipBucket:" + index} className="custom-tooltip">
                                                <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                    A security Rule Bucket defines Column name that should not occure. If the Bucket logic is OR none of the Values in the Column are allowed to occure. If the Bucket logic is AND the Values of a single table row are not allowed to occure together.
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>                                    <Select
                                        styles={
                                            {
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                }),
                                                menu: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: "var(--background)",
                                                    color: "var(--text-icons)",
                                                    zIndex: "2"
                                                }),
                                                option: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                    zIndex: "2"
                                                }),
                                                group: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    zIndex: "2"
                                                }),
                                                valueContainer: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: "var(--background)"
                                                }),
                                                singleValue: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: "var(--background)",
                                                }),
                                                multiValue: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",
                                                }),
                                                multiValueLabel: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "var(--text-icons)",
                                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)",

                                                }),
                                                container: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    width: "100%",
                                                    margin: "5px",

                                                })
                                            }}
                                        options={ruleState.buckets[index].columns.length > 0 ?
                                            securedTables.filter(table => table.tableID === ruleState.buckets[index].columns[0].tableID)
                                                .flatMap(table => table.columns.map((column) => ({
                                                    columnID: column.id,
                                                    name: column.name,
                                                    tableID: column.tableID,
                                                    phoneticCheck: false,
                                                    spellCheck: false
                                                }))) :
                                            securedTables.flatMap(table => table.columns.map((column) => ({
                                                columnID: column.id,
                                                name: column.name,
                                                tableID: column.tableID,
                                                phoneticCheck: false,
                                                spellCheck: false
                                            })))
                                        }
                                        getOptionLabel={(column) => column.name + "(" + securedTables.filter(table=> table.tableID === column.tableID)[0].tableName + ")"}
                                        getOptionValue={(column) => column.name}
                                        onChange={(selectedOptions) => {
                                            console.log("selectedOptions Column Names:", selectedOptions);
                                            ruleState.buckets[index] = { ...ruleState.buckets[index], columns: [...selectedOptions] || [] };
                                            postUpdateSecurityRule();
                                            onSecurityRulesChanged();

                                            console.log("Bucket Column Names:", ruleState.buckets[index].columns);
                                        }}
                                        value={ruleState.buckets[index].columns}
                                        isMulti={true}
                                        menuPortalTarget={document.body}
                                        menuPosition={'absolute'}
                                        menuPlacement={'auto'}
                                    />
                                    <div style={{ display: "flex", flexDirection: "column", margin: "10px", width: "100%" }}>
                                        {bucket.columns.map((bucketColumn, bcIdx) => (
                                            <BucketColumnTile
                                                key={"new_bucket" + index + "bucketColumn" + bcIdx}
                                                bucketColumn={bucketColumn}
                                                onBucketColumnTileChanged={(updatedBucketColumn) => {
                                                    console.log("Button Column Changed")
                                                    var updatedBuckets = [...ruleState.buckets];
                                                    var updatedColumns = [...updatedBuckets[index].columns]
                                                    updatedColumns[bcIdx] = updatedBucketColumn
                                                    updatedBuckets[index] = { ...updatedBuckets[index], columns: updatedColumns };
                                                    postUpdateSecurityRule();
                                                    onSecurityRulesChanged();
                                                }}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div style={{ display: "flex", flex: "1", flexDirection: "column", height: "100%", alignItems: "center", justifyContent: "flex-start" }}>
                                    <div style={{ margin: "5px", fontWeight: 'bold' }}>Bucket Logic</div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "80%", justifyContent: "center" }}>
                                        <div style={{ flex: "1", marginRight: "10px", textAlign: "center" }}> {ruleState.buckets[index].type ? "AND" : "OR"} </div>
                                        <div style={{ flex: "1" }}>
                                            <Switch
                                                checked={ruleState.buckets[index].type}
                                                onChange={() => {
                                                    console.log("Switching Bucket Type")
                                                    ruleState.buckets[index] = { ...ruleState.buckets[index], type: !ruleState.buckets[index].type };
                                                    postUpdateSecurityRule();
                                                    onSecurityRulesChanged();
                                                }}
                                                offColor="#676767"
                                                onColor="#676767"
                                                checkedIcon={
                                                    <div style={{ textAlign: "center" }}>
                                                        &
                                                    </div>
                                                }
                                                uncheckedIcon={
                                                    <div style={{ textAlign: "center" }}>
                                                        |
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="settings-button "
                                        style={{ height: "inherit", maxHeight: "40px" }}
                                        onClick={() => {
                                            ruleState.buckets = [...ruleState.buckets].filter((val, idx) => index !== idx);
                                            ruleState.bucket_connects = ruleState.bucket_connects.filter((con, i) => {
                                                if (index === 0 && i === 0) {
                                                    return false
                                                }
                                                else {
                                                    return con.idB !== bucket.id
                                                }
                                            });
                                            postUpdateSecurityRule();
                                            onSecurityRulesChanged();
                                        }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <div>Remove Bucket</div>
                                            <FaTrash className="cancel-delete-icon" />
                                        </div>
                                    </div>
                                </div>
                                {index < ruleState.buckets.length - 1 &&
                                    <div className="bucket-connection-tile" data-tooltip-id={"BucketConTooltip" + index}>Connection Logic
                                        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                                            {ruleState.bucket_connects[index].connectionType ? "AND" : "OR"}
                                        </div>
                                        <Switch
                                            checked={ruleState.bucket_connects[index].connectionType}
                                            onChange={() => {
                                                console.log("Switching Bucket Connection Type")
                                                ruleState.bucket_connects[index] = { ...ruleState.bucket_connects[index], connectionType: !ruleState.bucket_connects[index].connectionType };
                                                postUpdateSecurityRule();
                                                onSecurityRulesChanged();
                                            }}
                                            offColor="#676767"
                                            onColor="#676767"
                                            checkedIcon={
                                                <div style={{ textAlign: "center" }}>
                                                    &
                                                </div>
                                            }
                                            uncheckedIcon={
                                                <div style={{ textAlign: "center" }}>
                                                    |
                                                </div>
                                            }
                                        />
                                        <Tooltip id={"BucketConTooltip" + index} className="custom-tooltip" delayShow={500}>
                                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                                The Bucket Connection Logic defines which Buckets need to have matches for the Rule to be broken.
                                            </div>
                                        </Tooltip>
                                    </div>}
                            </div>
                        ))}


                        <div style={{ width: "100%", borderTop: "solid 2px var(--gray-500)" }} />
                        <div
                            className="settings-button "
                            style={{ height: "inherit" }}
                            onClick={() => {
                                ruleState.buckets = [...ruleState.buckets,
                                {
                                    "columns": [],
                                    "type": false,
                                    "id": null,
                                }
                                ];

                                if (ruleState.buckets.length >= 2) {
                                    ruleState.bucket_connects = [...ruleState.bucket_connects,
                                    {
                                        "idA": null,
                                        "idB": null,
                                        "connectionType": false
                                    }]

                                }
                                postUpdateSecurityRule();
                                onSecurityRulesChanged();
                            }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div>New Logic Bucket</div>
                                <div style={{ color: "blue", marginLeft: "15px", textAlign: "center" }}> &| </div>
                            </div>
                        </div>

                    </div>


                    <div className="settings-button" style={{ color: "red", borderColor: "red" }}
                        onClick={
                            () => {
                                deleteSecurityRule();
                            }}
                    >
                        <FaTrash style={{ marginRight: '5px' }} />
                        Delete Security Rule
                    </div>
                </div>
            </div>
        </AnimateHeight>
    );
}
