import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faHouse } from '@fortawesome/free-solid-svg-icons';
import PrivcyPromptLogo from './../assets/privacyprompt.svg'
import './Navbar.css'
import { Link } from "react-router-dom";
import Select from "react-select";
import LLMSelectOption from "./LLMSelectOption";
import LLMSelectValue from "./LLMSelectValue";
import LLMSelectSingleValueContainer from "./LLMSelectSingleValueContainer";

export default function Navbar({ isHome, availableLLMs, onSelectedLLMChanged }) {

  const [selectedLLM, setSelectedLLM] = useState(availableLLMs ? availableLLMs[0] : null)
  const [llmOptions, setLlmOptions] = useState([]);

  useEffect(() => {
    if (availableLLMs) {
      const previously_selected = Number(localStorage.getItem("selected_llm"));
      //console.log("Previously Selected LLM:", previously_selected);
      //console.log("Availabale LLMS:", availableLLMs)
      //console.log("Filtered Availabale LLMS:", availableLLMs.filter(llm => llm.api_id === previously_selected))
      if (previously_selected && availableLLMs.filter(llm => llm.api_id === previously_selected).length >= 1) {
        //console.log("Setting with previously selected");
        setSelectedLLM(availableLLMs.filter(llm => llm.api_id === previously_selected)[0]);
        onSelectedLLMChanged(availableLLMs.filter(llm => llm.api_id === previously_selected)[0].api_id)
      } else if (availableLLMs.length > 0) {
        setSelectedLLM(availableLLMs[0])
        onSelectedLLMChanged(availableLLMs[0].api_id)
      }
      setLlmOptions(availableLLMs.map(llm => {
        return {
          value: llm.api_id,
          label: [
                   llm.api_name,
                   llm.icon_number
          ]
        }
      }))
    }
  }, [availableLLMs])

  useEffect(() => {
    //console.log("Navbar selested LLM:", selectedLLM)
    //console.log(llmOptions.find(ele => ele.value === selectedLLM.api_id))
  }, [selectedLLM])

  return (
    <header className="navbar-header">
      {isHome && availableLLMs && selectedLLM &&
        <div className="model-select">
          <Select
            value={llmOptions.find(ele => ele.value === selectedLLM.api_id)}
            styles={
              {
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                  backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                }),
                menu: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "var(--text-icons)",
                  backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "var(--text-icons)",
                  backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                }),
                valueContainer: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "var(--text-icons)",
                  backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                }),
                singleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "var(--text-icons)",
                  backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                }),
                multiValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "var(--text-icons)",
                  backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                }),
                multiValueLabel: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "var(--text-icons)",
                  backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                }),
                container: (baseStyles, state) => ({
                  ...baseStyles,
                  width: "100%",
                  margin: "5px"
                })
              }}
            options={llmOptions}
            components={{Option:LLMSelectOption, SingleValue:LLMSelectValue, ValueContainer:LLMSelectSingleValueContainer }}
            onChange={(selectedOptions) => {
              //console.log("selectedOptions:", selectedOptions);
              setSelectedLLM(availableLLMs.find(llm => llm.api_id === selectedOptions.value))
              localStorage.setItem("selected_llm", selectedOptions.value)
              onSelectedLLMChanged(selectedOptions.value)
            }}
          />
        </div>

      }
      <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "center"}}>
        <Link to="/impressum" style={{display: "flex", flexDirection: "row", justifyContent:"center", alignItems:"center", cursor: "pointer", textDecoration:"none", color:"var(--text-icons)" }}>
          <img src={PrivcyPromptLogo} alt="PrivacyPrompt Logo" style={{ maxHeight: "60px" }} />
          <h4 style={{whiteSpace:"nowrap"}}>{isHome ? "Privacy Prompt" : "Privacy Prompt Settings"}</h4>
        </Link>
      </div>


      <center className="navbar">
        {isHome ?
          <Link to="/settings">
            <FontAwesomeIcon icon={faGear} style={{ margin: '8px', fontSize: '24px', color: 'var(--text-icons) ' }} />
          </Link> :
          <Link to="/">
            <FontAwesomeIcon icon={faHouse} style={{ margin: '8px', fontSize: '24px', color: 'var(--text-icons) ' }} />
          </Link>
        }
      </center>
    </header>
  );
}
