import { useRef, useState, useEffect } from 'react';
import { FaTrash, FaAngleUp, FaAngleDown, FaTimes, FaEdit, FaCheck, FaInfoCircle } from "react-icons/fa";
import AnimateHeight, { Height } from "react-animate-height"
import { CompanyPolicy } from '../SettingsTypedefs';
import { Tooltip } from 'react-tooltip';





export default function PolicyTile({ apiURL, policy, onPolicysChanged, ...props }:
    { apiURL: string, policy: CompanyPolicy, onPolicysChanged: Function }) {
    const [expanded, setExpanded] = useState(false)
    const [height, setHeight] = useState<Height>('auto');
    const contentDiv = useRef<HTMLDivElement | null>(null);
    const [editMode, setEditMode] = useState(false);
    const [editedPolicy, setEditedPolicy] = useState<CompanyPolicy | null>(null);
    useEffect(() => {
        const element = contentDiv.current as HTMLDivElement;

        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
    }, []);

    useEffect(() => {
        if (policy) {
            setEditedPolicy({ ...policy });
        }
    }, [policy])

    async function deletePolicy() {
        try {
            const response = await fetch(
                apiURL + "/delete_company_policy?policyID=" + policy.policyID,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                onPolicysChanged();
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }

    async function updatePolicy() {
        try {
            const response = await fetch(
                apiURL + "/update_company_policy",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(editedPolicy)
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                onPolicysChanged();
                setEditMode(false)
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }


    return (
        <AnimateHeight
            height={height}
            contentClassName="auto-content"
            contentRef={contentDiv}
            disableDisplayNone
            style={{ padding: "10px", width: "90%", border: "solid 1px var(--text-icons-2)", borderRadius: "10px", marginBottom: "10px", overflow: "visible" }}>
            <div ref={contentDiv}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", cursor: "pointer" }}
                    onClick={() => setExpanded(!expanded)}
                >   <div style={{ flex: "5" }}>
                        {policy.policyName}
                    </div>
                    <div style={{ flex: "1", display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end" }}>
                        {expanded ?
                            <FaAngleUp onClick={() => setExpanded(false)} style={{ cursor: 'pointer' }} /> :
                            <FaAngleDown />
                        }
                    </div>
                </div>
                <div style={expanded ? { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "15px", width: "100%" } :
                    { display: "none" }}>
                    <div style={{ width: "100%", borderTop: "solid 2px var(--gray-500)" }} />

                    <div style={{ display: "flex", flex: "1", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", width: "90%" }}>
                        <div style={{ margin: "5px", fontWeight: 'bold', display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ marginRight: "5px" }}>Policy Name</div>
                            <div data-tooltip-id="CompanyNPPNTooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                <FaInfoCircle />
                                <Tooltip id="CompanyNPPNTooltip" className="custom-tooltip">
                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                        Enter the name under which this policy should be shown in further settings here.
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        {editMode ?
                            <input
                                value={policy.policyName}
                                onChange={(e => {
                                    setEditedPolicy(
                                        { ...editedPolicy!, policyName: e.target.value }
                                    );
                                })}
                                className="settings-input"
                                placeholder="New policy Name"
                            /> :
                            <div style={{ marginLeft: "15px" }}> {policy.policyName} </div>
                        }

                        <div style={{ margin: "5px", fontWeight: 'bold', display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ marginRight: "5px", fontWeight: 'bold' }}>Policy Directive </div>
                            <div data-tooltip-id="CompanyNPPDTooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                <FaInfoCircle />
                                <Tooltip id="CompanyNPPDTooltip" className="custom-tooltip">
                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                        Enter a directive about what should be followed. Our AI will check if this directive is kept. For example: "Do not talk badly about ethnic minoritys"
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        {editMode ?
                            <textarea
                                id='chatInput'
                                className='new_privat_data_input'
                                style={{ height: '100%', minHeight: "200px", border: "solid 1px var(--text-icons-2)" }}
                                placeholder="Give a directive what should not be talked about"
                                value={editedPolicy!.directive}
                                maxLength={50000}
                                onChange={(e) => {
                                    setEditedPolicy(
                                        { ...editedPolicy!, directive: e.target.value }
                                    );
                                }}
                            /> :
                            <div style={{ marginLeft: "15px" }}> {policy.directive} </div>
                        }
                        <div style={{ margin: "5px", fontWeight: 'bold', display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ marginRight: "5px", fontWeight: 'bold' }}>Policy Hint </div>
                            <div data-tooltip-id="CompanyNPPHTooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                <FaInfoCircle />
                                <Tooltip id="CompanyNPPHTooltip" className="custom-tooltip">
                                    <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                        Enter a hint text that will be shown to the users when violating the directive, with a prompt to correct the question that will be send to the Large Language Model.
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        {editMode ?
                            <input
                                value={editedPolicy!.hint}
                                onChange={(e => {
                                    setEditedPolicy(
                                        { ...editedPolicy!, hint: e.target.value }
                                    );
                                })}
                                className="settings-input"
                                placeholder="This hint will be shown to users when violating the policy"
                            /> :
                            <div style={{ marginLeft: "15px" }}> {policy.hint} </div>
                        }
                    </div>
                    {editMode ?
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            <div className="settings-button"
                                onClick={
                                    () => {
                                        setEditMode(false);
                                    }}
                            >
                                Cancel
                                <FaTimes style={{ marginRight: '5px', color: "red" }} />

                            </div>
                            <div className="settings-button"
                                onClick={
                                    () => {
                                        updatePolicy();
                                    }}
                            >
                                Confirm Changes
                                <FaCheck style={{ color: "green", borderColor: "green" }} />

                            </div>
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            <div className="settings-button"
                                onClick={
                                    () => {
                                        setEditMode(true);
                                    }}
                            >
                                <FaEdit style={{ marginRight: '5px' }} />
                                Edit Policy
                            </div>
                            <div className="settings-button" style={{ color: "red", borderColor: "red" }}
                                onClick={
                                    () => {
                                        deletePolicy();
                                    }}
                            >
                                <FaTrash style={{ marginRight: '5px' }} />
                                Delete Policy
                            </div>
                        </div>
                    }

                </div>
            </div>
        </AnimateHeight>
    );
}
