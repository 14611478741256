import { FaTimes, FaPlusCircle, FaInfoCircle } from "react-icons/fa";
import { useEffect, useState } from "react";
import { CompanyPolicy } from "../SettingsTypedefs";
import "./../Settings.css"
import PolicyTile from "./PolicyTile";
import { Tooltip } from 'react-tooltip';

export default function SecurityPolicysSettings({ apiURL, onSecurityPolicysChanged }: {apiURL:string, onSecurityPolicysChanged:Function}) {
    const [companyPolicys, setCompanyPolicys] = useState<CompanyPolicy[]>([]);
    const [newPolicyMode, setNewPolicyMode] = useState(false);
    const [newPolicy, setNewPolicy] = useState({
        "directive": "",
        "hint": "",
        "policyName": "",

    })

    useEffect(() => {
        if (apiURL) {
            fetchCompanyPolicys();
        }

    }, [apiURL])



    async function postNewCompanyPolicy() {
        try {
            const response = await fetch(
                apiURL + "/add_company_policy",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(newPolicy)
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                setTimeout(() => {
                    setNewPolicyMode(false);
                    fetchCompanyPolicys();
                    setNewPolicy(
                        {
                            "directive": "",
                            "hint": "",
                            "policyName": ""
                        }
                    );
                }, 200);
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }

    async function fetchCompanyPolicys() {
        try {
            const response = await fetch(
                apiURL + "/get_company_policys",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                const data = await response.json()
                setCompanyPolicys(data)
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }


    return (
        <div className="subsetting-content">
            <center><h4>Company Policys</h4></center>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                {!newPolicyMode ?
                    <div
                        data-tooltip-id="CompanyNewPolicyTooltip"
                        className="settings-button"
                        onClick={() => {
                            setNewPolicyMode(true);
                        }}
                    >
                        Add new Company Policy
                        <FaPlusCircle className="add-element-icon" />
                        <Tooltip id="CompanyNewPolicyTooltip" className="custom-tooltip" delayShow={800}>
                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                Here you can add a new Company Policy. Once this Policy is assigned to a security level, every request with the security level will be cchecked by AI if the directive of the Policy is fullfilled.
                            </div>
                        </Tooltip>
                    </div> :
                    <div className="setting-add-element-container">
                        <div style={{ display: "flex", flex: "1", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between" }}>
                            <div style={{ margin: "5px", fontWeight: 'bold', display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                <div style={{ marginRight: "5px" }}>Policy Name</div>
                                <div data-tooltip-id="CompanyNPPNTooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                    <FaInfoCircle />
                                    <Tooltip id="CompanyNPPNTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            Enter the name under which this policy should be shown in further settings here.
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <input
                                value={newPolicy.policyName}
                                onChange={(e => {
                                    setNewPolicy(
                                        { ...newPolicy, policyName: e.target.value }
                                    );
                                })}
                                className="settings-input"
                                placeholder="New policy Name"
                            />
                            <div style={{ margin: "5px", fontWeight: 'bold', display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                <div style={{ marginRight: "5px", fontWeight: 'bold' }}>Policy Directive </div>
                                <div data-tooltip-id="CompanyNPPDTooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                    <FaInfoCircle />
                                    <Tooltip id="CompanyNPPDTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            Enter a directive about what should be followed. Our AI will check if this directive is kept. For example: "Do not talk badly about ethnic minoritys"
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <textarea
                                id='chatInput'
                                className='new_privat_data_input'
                                style={{ height: '100%', minHeight: "200px", border: "solid 1px var(--text-icons-2)" }}
                                placeholder="Give a directive what should not be talked about"
                                value={newPolicy.directive}
                                maxLength={50000}
                                onChange={(e) => {
                                    setNewPolicy(
                                        { ...newPolicy, directive: e.target.value }
                                    );
                                }}
                            />
                            <div style={{ margin: "5px", fontWeight: 'bold', display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                <div style={{ marginRight: "5px", fontWeight: 'bold' }}>Policy Hint </div>
                                <div data-tooltip-id="CompanyNPPHTooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                    <FaInfoCircle />
                                    <Tooltip id="CompanyNPPHTooltip" className="custom-tooltip">
                                        <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                            Enter a hint text that will be shown to the users when violating the directive, with a prompt to correct the question that will be send to the Large Language Model.
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <input
                                value={newPolicy.hint}
                                onChange={(e => {
                                    setNewPolicy(
                                        { ...newPolicy, hint: e.target.value }
                                    );
                                })}
                                className="settings-input"
                                placeholder="This hint will be shown to users when violating the policy"
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", width: "90%", alignItems: "center", justifyContent: "center", alignSelf: "center" }}>
                            <div
                                className="settings-button "
                                style={{ height: "inherit" }}
                                onClick={() => {
                                    setNewPolicyMode(false);
                                    setNewPolicy(
                                        {
                                            "directive": "",
                                            "hint": "",
                                            "policyName": "",

                                        }
                                    );
                                }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div>Cancel</div>
                                    <FaTimes className="cancel-delete-icon" />
                                </div>
                            </div>
                            <div
                                className="settings-button"
                                onClick={() => {
                                    console.log("Posting new Company Policy")
                                    postNewCompanyPolicy();
                                }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div>Add</div>
                                    <FaPlusCircle className="confirm-icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                 <center><h5>Existing Security Policys:</h5></center>
                {companyPolicys.map((policy) =>
                    <PolicyTile
                        key={"policy" + policy.policyID}
                        apiURL={apiURL}
                        policy={policy}
                        onPolicysChanged={() => {
                            setTimeout(() => {
                                fetchCompanyPolicys();
                                onSecurityPolicysChanged();
                            }, 200)
                        }}
                    />
                )}
            </div>
        </div>
    );
}
