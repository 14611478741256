import { FaEdit, FaCheck, FaTimes, FaInfoCircle } from "react-icons/fa";
import { useEffect, useState } from "react";
import Switch from 'react-switch';
import Select from "react-select";
import "./../Settings.css"
import { Tooltip } from 'react-tooltip';

export default function CompanySettings({ userData, companyData, companyUsers, onCompanyDataChanged, apiURL }) {
    const [editNameMode, setEditNameMode] = useState(false);
    const [editedName, setEditedName] = useState("");
    useEffect(() => {
        if (companyData) {
            setEditedName(companyData.companyName);
        }
    }, [companyData])

    async function postUpdatedCompanyData(updatedCompanyData) {
        try {
            const response = await fetch(
                apiURL + "/update_company_data",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    body: JSON.stringify(updatedCompanyData)
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                setTimeout(() => {
                    onCompanyDataChanged();
                }, 200);
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }



    return (
        <div className="subsetting-content">
            <center><h4>Company Settings</h4></center>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {editNameMode ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px', fontSize: "22px" }}>
                        <input
                            type="text"
                            className="settings-input"
                            style={{ fontWeight: 'bold' }}
                            value={editedName}
                            onChange={(e) => {
                                setEditedName(e.target.value)
                            }}
                        />
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <div
                                className="settings-button"
                                style={{ fontSize: "16px" }}
                                onClick={() => {
                                    setEditNameMode(false);
                                }}>
                                Cancel
                                <FaTimes className="cancel-delete-icon" />
                            </div>
                            <div
                                className="settings-button"
                                style={{ fontSize: "16px" }}
                                onClick={() => {
                                    const updatedCompanyData = { ...companyData };
                                    updatedCompanyData.companyName = editedName;
                                    postUpdatedCompanyData(updatedCompanyData);
                                    setEditNameMode(false);
                                }}>
                                Confirm Changes
                                <FaCheck className="confirm-icon" />
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <h2 style={{ marginRight: "20px" }}>{companyData ? companyData.companyName : ""}</h2>
                        <FaEdit fontSize={22}
                            onClick={() => {
                                setEditNameMode(true);
                            }}
                        />
                    </div>}
                <div style={{ display: 'flex', flexDirection: 'row', width: '80%' }}>
                    <div style={{ display: 'flex', flex: '2', flexDirection: 'column', fontWeight: 'bold' }}>
                        <div>
                            Admin Name:
                        </div>
                        <div>
                            Admin Mail:
                        </div>
                    </div>
                    <div style={{ display: 'flex', flex: '2', flexDirection: 'column', marginLeft: '5px' }}>
                        <div>
                            {companyData.pp_admin_name}
                        </div>
                        <div>
                            {companyData.pp_admin_mail}
                        </div>
                    </div>
                    {userData.userRole === 1 &&  companyUsers && <div style={{ display: 'flex', flex: '2', alignItems: 'center', justifyContent: 'center', padding: "5px" }}>
                        <Select
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? "var(--text-icons)" : "var(--text-icons-2)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                }),
                                menu: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                }),
                                valueContainer: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                }),
                                singleValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "var(--text-icons)",
                                    backgroundColor: state.isFocused ? "var(--background-2)" : "var(--background)"
                                }),
                                container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: "100%",
                                    margin: "5px"
                                })
                            }}
                            options={companyUsers.filter(user => user.userRole === 1).map(user => ({
                                value: user.userID,
                                label: user.name,
                                email: user.email
                            }))}
                            onChange={(selectedOption) => {
                                console.log(selectedOption);
                                const updatedCompanyData = { ...companyData };
                                updatedCompanyData.pp_admin_name = selectedOption.label;
                                updatedCompanyData.pp_admin_mail = selectedOption.email;
                                postUpdatedCompanyData(updatedCompanyData);
                            }}
                            disabled={userData.userRole > 1}
                        />
                    </div>}
                    <div style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} data-tooltip-id="CompanyContactTooltip">
                        <FaInfoCircle />
                        <Tooltip id="CompanyContactTooltip" className="custom-tooltip">
                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                The selected admin will be shown to all users as contact person. Also the selected account can not be deleted to ensure that alwas at least one admin is registered.
                            </div>
                        </Tooltip>
                    </div>

                </div>
                {/*  Company Two Fa Mandatory */}
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', width: '80%' }}>
                    <div style={{ fontWeight: 'bold', flex: '2' }}> Two Fa Auth Mandatory: </div>
                    <div style={{ marginLeft: '5px', flex: '2' }}> {companyData.twoFaMandatory ? "Activated" : "Deactivated"} </div>
                    <div style={{ marginLeft: '15px', display: 'flex', flex: '2', alignItems: 'center', justifyContent: 'center' }}>
                        <Switch
                            disabled={userData.userRole > 1}
                            checked={companyData.twoFaMandatory}
                            onChange={() => {
                                const updatedCompanyData = { ...companyData };
                                updatedCompanyData.twoFaMandatory = !updatedCompanyData.twoFaMandatory;
                                postUpdatedCompanyData(updatedCompanyData);
                            }}
                        />
                    </div>
                    <div data-tooltip-id="CompanyTFAMTooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <FaInfoCircle />
                        <Tooltip id="CompanyTFAMTooltip" className="custom-tooltip">
                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                This overwrites the User preferences for two Factor Authentification. If activated every user has to use two factor authentification when the login token is expired.
                            </div>
                        </Tooltip>
                    </div>
                </div>
                {/*  ALLOW REGISTRATION */}
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', width: '80%' }}>
                    <div style={{ fontWeight: 'bold', flex: '2' }}> Allow registration: </div>
                    <div style={{ marginLeft: '5px', flex: '2' }}>{companyData.allowRegistration ? "Activated" : "Deactivated"}</div>
                    <div style={{ marginLeft: '15px', display: 'flex', flex: '2', alignItems: 'center', justifyContent: 'center' }}>
                        <Switch
                            disabled={userData.userRole > 1}
                            checked={companyData.allowRegistration}
                            onChange={() => {
                                const updatedCompanyData = { ...companyData };
                                updatedCompanyData.allowRegistration = !updatedCompanyData.allowRegistration;
                                postUpdatedCompanyData(updatedCompanyData);
                            }}
                        />
                    </div>
                    <div data-tooltip-id="CompanyARTooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <FaInfoCircle />
                        <Tooltip id="CompanyARTooltip" className="custom-tooltip">
                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                If activated users can register themself with only the URL of the PrivacyPrompt API. If deactivated user registration is only possible for admins in the "Company Accounts" tab of the Settings.
                            </div>
                        </Tooltip>
                    </div>
                </div>
                {/*  Hidden auto anonymize */}
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', width: '80%' }}>
                    <div style={{ fontWeight: 'bold', flex: '2' }}> Hidden automatic anonymization: </div>
                    <div style={{ marginLeft: '5px', flex: '2' }}>{companyData.hiddenAutoAnonymize ? "Activated" : "Deactivated"}</div>
                    <div style={{ marginLeft: '15px', display: 'flex', flex: '2', alignItems: 'center', justifyContent: 'center' }}>
                        <Switch
                            disabled={userData.userRole > 1}
                            checked={companyData.hiddenAutoAnonymize}
                            onChange={() => {
                                const updatedCompanyData = { ...companyData };
                                updatedCompanyData.hiddenAutoAnonymize = !updatedCompanyData.hiddenAutoAnonymize;
                                postUpdatedCompanyData(updatedCompanyData);
                            }}
                        />
                    </div>
                    <div data-tooltip-id="CompanyARTooltip" style={{ flex: "1", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <FaInfoCircle />
                        <Tooltip id="CompanyARTooltip" className="custom-tooltip">
                            <div style={{ maxWidth: "20vw", wordBreak: "break-word" }}>
                                With automatic anonymization the user wont be shown the critical content and be prompted to correct it. Instead the critical content will be replaced by tags that are retranslated in the answer. Some context migth be lost for the LLM.
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
}
