import { useEffect, useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import SystemMessageTile from "./SystemMessageTile";

export default function ChatConfigSettings({ userData, availableLLMs, apiURL }) {
    const [systemMessages, setSystemMessages] = useState(null);


    useEffect(() => {
        fetchSystemMessages();
    }, [])


    async function postNewSystemMessage(inputID, apiID) {
        const newSystemMessageInput = document.getElementById(inputID)
        const newMessage = newSystemMessageInput.value;
        try {
            const encodedMessage = encodeURIComponent(newMessage);
            const response = await fetch(
                apiURL + "/add_new_system_message?message=" + encodedMessage + "&apiID=" + apiID,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                newSystemMessageInput.value = ""
                setTimeout(() => {
                    fetchSystemMessages();
                }, 200);
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }

    async function fetchSystemMessages() {
        var apiLink = apiURL + "/get_system_messages";
        try {
            const response = await fetch(apiLink, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });

            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                alert("Error: " + response.status);
            } else {
                const successData = await response.json();

                const fetchedMessages = successData.system_messages.map(entry => {
                    return {
                        "ID": entry[0],
                        "apiID": entry[1],
                        "userID": entry[2],
                        "system_message": entry[3]
                    }
                })
                setSystemMessages(fetchedMessages);
            }
        } catch (error) {
            console.log("Error occurred while fetching system Messages:", error);
        }

    }

    return (
        <div className="subsetting-content">
            <center><h4>Chat Settings</h4></center>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                Configure System Messages for your Large Language Models. These Messages are send with each Prompt and can be used to specify the wanted output.
                {
                    availableLLMs.map((llm_data, index) => (
                        <div key={"LLM" + index}
                            style={{ width: '90%' }}
                        >
                            <h5>{llm_data.api_name}</h5>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '90%', alignItems: 'center', justifyContent: 'center' }}>
                                <input
                                    id={"InputNewSystemMessage" + llm_data.api_name}
                                    placeholder="New System Message"
                                    className="settings-input"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            postNewSystemMessage("InputNewSystemMessage" + llm_data.api_name, llm_data.api_id)
                                        }
                                    }}
                                />
                                <FaPlusCircle className="confirm-icon"
                                    onClick={() => {
                                        postNewSystemMessage("InputNewSystemMessage" + llm_data.api_name, llm_data.api_id)
                                    }} />
                            </div>
                            {
                                systemMessages ? systemMessages.filter((message) => { return message.apiID === llm_data.api_id }).map((message) => (
                                    <SystemMessageTile
                                        key={"SysMessTile"+ message.ID}
                                        apiURL={apiURL}
                                        message={message}
                                        onSystemMessagesChanged={() => {
                                            fetchSystemMessages();
                                        }}
                                    />
                                )) : <></>
                            }

                        </div>
                    ))
                }
            </div>
        </div>
    );
}



