import  { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { FaPlus } from 'react-icons/fa';
import './TopicSelection.css'
import TopicSelectionTile from "./TopicSelectionTile";
import AccountTile from "../../Components/AccountTile";
const TopicSelection = forwardRef(({ onSelcetedTopicChanged, apiURL, onLogout, userData }, ref) => {
    const [categorized_topis, setCategorizedTopics] = useState([]);
    const [newTopic, setNewTopic] = useState("");
    const [selectedTopic, setSelectedTopic] = useState(null);

    useEffect(() => {
        fetchTopics();
    }, []);

    useEffect(() => {
        console.log("TopicSelection useEffect selectedTopic:", selectedTopic)
        if (selectedTopic) {
            onSelcetedTopicChanged(selectedTopic.topic_id);
        } else {
            onSelcetedTopicChanged(-1);
        }
    }, [selectedTopic]);

    async function add_topic(topic) {
        const ecodedTopic = encodeURIComponent(topic)
        var apiLink = apiURL + "/add_topic?topic=" + ecodedTopic;
        fetch(apiLink, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        }).then((response) => {
            // process response
            // console.log(response);
            if (response.status !== 200) {
                // error
                response.json().then((data) => {
                    //console.log(data);
                    alert("Error: " + response.status);
                });
            } else {
                // success
                response.json().then((data) => {
                    //console.log(data);
                    fetchTopics(true);
                });
            }
        });
    }

    async function fetchTopics(topic_expected = null) {
        var apiLink = apiURL + "/get_topics";
        try {
            const response = await fetch(apiLink, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            if (response.status !== 200) {
                const errorData = await response.json();
                console.log(errorData);
                alert("Error: " + response.status);
            } else {
                const successData = await response.json();
                const fetchedTopics = successData.topics.map(topic => {
                    return {
                        "topic_id": topic[0],
                        "topic_name": topic[2],
                        "update_timestamp": new Date(topic[3])
                    }
                });
                //console.log("Fetched Topics", fetchedTopics);
                const sortedTopics = fetchedTopics.sort((a, b) => b.update_timestamp - a.update_timestamp);
                //console.log("sortedTopics", sortedTopics);
                const cat_topics = categorize_topics_by_timestamps(sortedTopics);
                //console.log("cat_topics", cat_topics);
                setCategorizedTopics(cat_topics);
                if (topic_expected) {
                    setSelectedTopic(sortedTopics[0])
                }


            }
        } catch (error) {
            console.log("Error occurred while fetching conversation data:", error);
        }
    }

    function categorize_topics_by_timestamps(uncategorized_topics) {
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        const current_time = new Date().getTime();
        const today = new Date();
        var categorized_topis = {};
        uncategorized_topics.forEach(topic => {
            const topic_date = new Date(topic.update_timestamp);
            const time_diff = current_time - topic.update_timestamp;
            if (time_diff < 5 * 60 * 1000) {
                categorized_topis["Less than 5 minutes ago"] = categorized_topis["Less than 5 minutes ago"] ? [...categorized_topis["Less than 5 minutes ago"], topic] : [topic]
            }
            else if (time_diff < 60 * 60 * 1000) {
                categorized_topis["Less than one hour ago"] = categorized_topis["Less than one hour ago"] ? [...categorized_topis["Less than one hour ago"], topic] : [topic]
            }
            else if (today.getDay() === topic_date.getDay() && today.getMonth() === topic_date.getMonth() && today.getFullYear() === topic_date.getFullYear()) {
                categorized_topis["Today"] = categorized_topis["Today"] ? [...categorized_topis["Today"], topic] : [topic]
            }
            else if (today.getDay() - 1 === topic_date.getDay() && today.getMonth() === topic_date.getMonth() && today.getFullYear() === topic_date.getFullYear()) {
                categorized_topis["Yesterday"] = categorized_topis["Yesterday"] ? [...categorized_topis["Yesterday"], topic] : [topic]
            }
            else if (time_diff < 7 * 24 * 60 * 60 * 1000) {
                categorized_topis["Previous 7 Days"] = categorized_topis["Previous 7 Days"] ? [...categorized_topis["Previous 7 Days"], topic] : [topic]
            }
            else if (today.getMonth() === topic_date.getMonth() && today.getFullYear() === topic_date.getFullYear()) {
                categorized_topis["This Month"] = categorized_topis["This Month"] ? [...categorized_topis["This Month"], topic] : [topic]
            }
            else if (today.getFullYear() === topic_date.getFullYear()) {
                const topic_month = topic_date.getMonth();
                categorized_topis[months[topic_month]] = categorized_topis[months[topic_month]] ? [...categorized_topis[months[topic_month]], topic] : [topic]
            } else {
                const topic_year = topic_date.getFullYear();
                categorized_topis[topic_year] = categorized_topis[topic_year] ? [...categorized_topis[topic_year], topic] : [topic]
            }
        });
        return categorized_topis;
    }


    useImperativeHandle(ref, () => ({
        handle_topic_generated: () => {
            console.log("TopicSelection Recived Task to fetch Topics")
            setTimeout(() => {
                fetchTopics(true)
            }, 200);
        }
    }));

    return (
        <div className="topic-container">
            <div className="add-topic-container">
                <div className="add-topic-field-container">
                    <div className="add-topic-input-field">
                        <textarea
                            style={{whiteSpace:"nowrap"}}
                            id='new-topic-input'
                            type="text"
                            className="add-topic-textarea"
                            placeholder="Enter Topic"
                            value={newTopic}
                            onChange={(e) => {
                                if (e.target.value !== '\n') {
                                    setNewTopic(e.target.value);
                                }

                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    add_topic(newTopic)
                                    setNewTopic("");
                                }
                            }}
                        /* Add any state or event handlers as needed */
                        />
                        <button
                            className="add-topic-button"
                            onClick={() => {
                                add_topic(newTopic)
                                setNewTopic("");
                            }}
                        >
                            {<FaPlus className="customize-plus-icon" />}
                        </button>
                    </div>
                </div>

            </div>
            <div className="topic-selection">
                <div className="topic-selection-content">
                    {Object.keys(categorized_topis).map((category, categoryIndex) => (
                        <div key={`category-${categoryIndex}`}>
                            <div style={{ color: 'var(--background-4', fontSize: '12px', marginLeft: '10px', fontWeight: 'bold' }}>
                                {category}
                            </div>
                            {categorized_topis[category].map((topic, topicIndex) => (
                                <TopicSelectionTile
                                    apiURL={apiURL}
                                    topic={topic}
                                    onTopicSelected={() => {
                                        setSelectedTopic(topic);
                                    }}
                                    onTopicRemoved={() => {
                                        if (topic === selectedTopic) {
                                            setSelectedTopic(null)
                                            onSelcetedTopicChanged(-1);
                                        }
                                        fetchTopics();
                                    }}
                                    topicIndex={topicIndex}
                                    isSelected={selectedTopic ? selectedTopic.topic_id === topic.topic_id : false}
                                    key={`topic-tile-${topicIndex}`}
                                />
                            ))}
                        </div>
                    ))}
                </div>
                <div className="account-tile-container">
                    <AccountTile userData={userData} onLogout={() => {
                        onLogout();
                    }} />
                </div>

            </div>
        </div>
    );
});


export default TopicSelection;