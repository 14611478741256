// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-header {
    width: 100%;
    border: none;
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: var(--background);
    color: var(--text-icons);
    align-items: center;
    justify-content: center;
    top: 0;
    z-index: 100;
}


.navbar {
    display:flex;
    background-color: var(--background);
    color: var(--text-icons);
    height: 6vh;
    align-self: center;
    position:absolute;
    justify-content: center;
    align-items: center;
    right: 0;
}

.model-select {
    display: flex;
    position: absolute;
    left: 10px;
    border: none;
    min-width: 100px;
    max-width: 250px;
    width: 15vw;
    color: var(--text-icons);
    background-color: var(--background);
}



`, "",{"version":3,"sources":["webpack://./src/Components/Navbar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,mCAAmC;IACnC,wBAAwB;IACxB,mBAAmB;IACnB,uBAAuB;IACvB,MAAM;IACN,YAAY;AAChB;;;AAGA;IACI,YAAY;IACZ,mCAAmC;IACnC,wBAAwB;IACxB,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;IACX,wBAAwB;IACxB,mCAAmC;AACvC","sourcesContent":[".navbar-header {\r\n    width: 100%;\r\n    border: none;\r\n    position: relative;\r\n    display: flex;\r\n    flex-direction: row;\r\n    background-color: var(--background);\r\n    color: var(--text-icons);\r\n    align-items: center;\r\n    justify-content: center;\r\n    top: 0;\r\n    z-index: 100;\r\n}\r\n\r\n\r\n.navbar {\r\n    display:flex;\r\n    background-color: var(--background);\r\n    color: var(--text-icons);\r\n    height: 6vh;\r\n    align-self: center;\r\n    position:absolute;\r\n    justify-content: center;\r\n    align-items: center;\r\n    right: 0;\r\n}\r\n\r\n.model-select {\r\n    display: flex;\r\n    position: absolute;\r\n    left: 10px;\r\n    border: none;\r\n    min-width: 100px;\r\n    max-width: 250px;\r\n    width: 15vw;\r\n    color: var(--text-icons);\r\n    background-color: var(--background);\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
