import React, { useEffect, useState } from 'react';
import LLMIcon1 from './../../assets/LLMIcons/LLMIcon1.png'
import LLMIcon2 from './../../assets/LLMIcons/LLMIcon2.png'
import LLMIcon3 from './../../assets/LLMIcons/LLMIcon3.png'
import LLMIcon4 from './../../assets/LLMIcons/LLMIcon4.png'
import LLMIcon5 from './../../assets/LLMIcons/LLMIcon5.png'
import LLMIcon6 from './../../assets/LLMIcons/LLMIcon6.png'
import LamaIcon from './../../assets/LLMIcons/ollama.png'
import GPTIcon from './../../assets/LLMIcons/ChatGPT-Logo.png'


function  LLMIcon ({ iconNumber, maxHeight, maxWidth } : {iconNumber:number, maxHeight:string, maxWidth:string}){
    const [darkMode, setDarkMode] = useState(true);
    const iconSources = [LLMIcon1, LLMIcon2, LLMIcon3, LLMIcon4, LLMIcon5, LLMIcon6, LamaIcon, GPTIcon]
    const filter = darkMode
      ? 'invert(1) brightness(0.8)'
      : 'invert(0) brightness(1)'; // Adjust these values as needed

      useEffect(() => {
        if (localStorage.getItem('darkMode')) {
            setDarkMode(true);
        }
        else {
            setDarkMode(false);
        }
    }, [])
    
        useEffect(()=> {
            console.log("IN Icon recived:",  iconNumber, maxHeight, maxWidth )
        })
    return (
      <img
        src={iconSources[iconNumber] }
        style={darkMode ? { maxHeight: maxHeight, maxWidth: maxWidth, width: 'auto', display: 'block', filter:"invert(100%)", alignSelf:"center", justifySelf:"center" }:
         { maxHeight: maxHeight, maxWidth: maxWidth, width: 'auto', display: 'block', alignSelf:"center", justifySelf:"center"  }}     />
    );
  };
  export default LLMIcon;