import { useEffect, useState } from "react";
import { FaTrashAlt, FaEdit, FaCheck } from "react-icons/fa";

export default function SystemMessageTile({ message, apiURL, onSystemMessagesChanged }) {

    const [editedMessage, setEditedMessage] = useState(message.system_message);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        setEditedMessage(message.system_message);
    }, [message])


    async function deleteSystemMessage() {
        try {
            const response = await fetch(
                apiURL + "/delete_system_message?messageID=" + message.ID,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                setTimeout(() => {
                    onSystemMessagesChanged();
                }, 200);
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }

    async function updateSystemMessage() {
        try {
            const encodedMessage = encodeURIComponent(editedMessage);
            const response = await fetch(
                apiURL + "/update_system_message?messageID=" + message.ID + "&updatedMessage=" + encodedMessage,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status !== 200) {
                console.log("Response status: " + response.status);
            } else {
                setTimeout(() => {
                    onSystemMessagesChanged();
                }, 200);
            }
        } catch (error) {
            console.log(error);
            alert("Error: " + error);
        }
    }


    return (
        <div
            key={"SysMessage" + message.ID}
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                border: '1px solid var(--text-icons-3)',
                borderRadius: '10px',
                margin: '5px',
                padding: '5pxt',
                maxWidth: '90%',
                overflowX: 'hidden',
                lineBreak: 'anywhere'
            }}
        >
            {editMode ?
                <input
                    id="EditMessageInput"
                    className="settings-input"
                    value={editedMessage}
                    onKeyDown={(e) => {
                        if(e.key === 'Enter') {
                            updateSystemMessage();
                            setEditMode(false)
                            onSystemMessagesChanged();
                        }
                    }}
                    onChange={(e) => {
                        setEditedMessage(e.target.value);
                    }}
                /> : <div style={{ maxWidth: '90%', margin:'10px' }}> {message.system_message} </div>
            }
            <div>
                {!editMode ?
                    <FaEdit
                        style={{ margin: '5px', cursor:"pointer" }}
                        onClick={() => {
                            setEditMode(true);
                        }}
                    />:
                    <FaCheck 
                    className="confirm-icon"
                    onClick={()=> {
                        updateSystemMessage();
                        setEditMode(false)
                        onSystemMessagesChanged();
                    }}
                    />
                }
                <FaTrashAlt
                    className="cancel-delete-icon"
                    onClick={() => {
                        deleteSystemMessage();
                        onSystemMessagesChanged();
                    }}
                />
            </div>

        </div>
    )
}